import React, { useEffect, useState } from "react";
import styles from "./header-nav.module.scss";
import { NavLink, json, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { useLoginUserData } from "../../store/login";
import { Dropdown } from "primereact/dropdown";
import * as actionTypes from "../../store/actions";
import { setaxiosheader } from "../../api/api-client-factory";
import LogoImg from "../../assets/images/QuizLogo.jpg";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartItemsCount, setCartItemsCount] = useState(null);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const topicDetailsList = useSelector((state) => {
    return state.domainReducer.topicDetails;
  });
  const toggleNavbar = () => {
    setIsNavbarOpen((prev) => !prev);
  };

  // Close the navbar whenever the location (route) changes
  useEffect(() => {
    setIsNavbarOpen(false);
  }, [location]);
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 3; i++) {
      var value = Math.floor(Math.random() * 256);
      if (value > 230) {
        value = Math.floor(Math.random() * 230);
      }
      var hex = value.toString(16);
      if (hex.length < 2) {
        hex = "0" + hex;
      }
      color += hex;
    }
    return color;
  };
  const [initialsColor, setInitialsColor] = useState(getRandomColor());
  useEffect(() => {
    setInitialsColor(getRandomColor());
  }, []);

  const { setUserData, loginUserData } = useLoginUserData();

  // useEffect(() => {
  //   const userData = localStorage.getItem('userData');
  //   const token = localStorage.getItem('authToken');
  //   if (userData && token) {
  //     setUserData(JSON.parse(userData));
  //     setaxiosheader(`Bearer ${token}`);
  //   } else {
  //     setUserData(null);
  //   }
  // }, []);
  //   useEffect(() => {
  //     console.log('User data in navbar:', loginUserData);
  // }, [loginUserData]);

  useEffect(() => {
    let count = 0;
    let cartCount = JSON.parse(localStorage.getItem("ItemsAddedToCart"));
    for (let i = 0; i <= cartCount?.length - 1; i++) {
      for (let j = 0; j <= cartCount[i]?.topicQuizList.length - 1; j++) {
        if (
          cartCount[i]?.topicQuizList[j]?.addToCart &&
          cartCount[i]?.topicQuizList[j]?.isBundle &&
          cartCount[i]?.topicQuizList[j]?.quizTypeId !== 1
        ) {
          count++;
          break;
        } else if (
          cartCount[i]?.topicQuizList[j]?.addToCart &&
          cartCount[i]?.topicQuizList[j]?.quizTypeId !== 1
        ) {
          count++;
        }
      }
    }
    setCartItemsCount(count.toString());
  }, [topicDetailsList]);
  // const renderSearchBar = () => {
  //   const excludedPaths = ['/Login', '/ForgotPassword', '/verifyOtp', '/newPassword', '/Signup'];
  //   if (excludedPaths.includes(location.pathname)) {
  //     return null;
  //   }
  //   return (
  //     <div className={`${styles.search_div} ps-xl-5`}>
  //       <form className={`d-flex ${styles.search_bar} my-auto`} role="search">
  //         <input
  //           className="form-control p-2"
  //           type="search"
  //           placeholder="Search for any topic/quiz"
  //           aria-label="Search"
  //           id="search" name="searchbar"
  //         />
  //         <button className="btn d-flex justify-content-center align-items-center px-md-3" type="submit">
  //           <span className="px-2 d-flex justify-content-center align-items-center">
  //             <i className="pi pi-search"></i>
  //           </span>{" "}
  //           <span className="d-none d-md-block">Search</span>
  //         </button>
  //       </form>
  //     </div>
  //   );
  // };

  const getInitials = (name) => {
    if (!name || typeof name !== "string") {
      return "";
    }

    const names = name.split(" ");
    return names
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  };
  const initials = loginUserData
    ? getInitials(
        `${loginUserData.firstName.split(" ")[0]} ${
          loginUserData.lastName.split(" ")[0]
        }`
      )
    : "";

  const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== "string") {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const firstName = loginUserData
    ? capitalizeFirstLetter(loginUserData.firstName.split(" ")[0].slice(0, 10))
    : "";
  const lastName = loginUserData
    ? capitalizeFirstLetter(loginUserData.lastName.split(" ")[0].slice(0, 10))
    : "";
  const excludedPaths = [
    "/Login",
    "/ForgotPassword",
    "/verifyOtp",
    "/newPassword",
    "/Signup",
  ];
  const shouldHideButtons = excludedPaths.includes(location.pathname);

  let dropdownOptions = [
    { label: "My Account", value: "myaccount" },
    { label: "Logout", value: "logout" },
  ];

  if (loginUserData) {
    if (loginUserData?.roleId === 1 || loginUserData?.roleId === 4) {
      dropdownOptions = [
        { label: "Add Admin", value: "add_admin" },
        { label: "My Account", value: "myaccount" },
        { label: "Manage Quizzes", value: "managequizzes" },
        { label: "Logout", value: "logout" },
      ];
    } else if (loginUserData?.roleId === 5) {
      dropdownOptions = [
        { label: "My Account", value: "myaccount" },
        { label: "Manage Quizzes", value: "managequizzes" },
        { label: "Logout", value: "logout" },
      ];
    }
  }
  const handleLogout = () => {
    handleReducerData();
    setUserData(null);   
    localStorage.clear();
    setaxiosheader();
    setSelectedOption(null);
    navigate("/");
  };
  const onDropdownChange = (e) => {
    setSelectedOption(e.value);
    if (e.value === "logout") {
      handleLogout();
    } else if (e.value === "add_admin") {
      navigate("/Addadmin");
    } else if (e.value === "myaccount") {
      navigate("/MyAccount");
    } else if (e.value === "managequizzes") {
      navigate("/QuizUpload");
    }
  };

  const handleReducerData = () => {
    const data = JSON.parse(localStorage.getItem("updatedTopics"));
    // if(data == null || data == undefined){
    //   dispatch({
    //     type: actionTypes.GET_TOPICDETAILS,
    //     value: []
    //   })
    // }
    dispatch({
      type: actionTypes.GET_TOPICDETAILS,
      value: [],
    });
  };
  const freeQuizId = 1;
  const navigateToFreeQuiz = (freeQuizId) => {
    navigate(`/Quiz/${freeQuizId}`);
  };
  return (
    <nav className="navbar navbar-expand-lg py-0">
      <div className="container-fluid py-2 px-4 mx-3">
        <NavLink className={`navbar-brand ${styles.logo}`} to="/">
          {/* <b>QUIZ</b> */}
          <div style={{ width: "6rem" }}>
            <img src={LogoImg} className="w-100" />
          </div>
        </NavLink>
        <button
          className={`navbar-toggler ${isNavbarOpen ? "" : "collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <div className="col-sm-10 col-md-8 col-lg-5 d-flex align-items-center">
            <div className="w-100 d-flex d-lg-block justify-content-center">
              {renderSearchBar()}
            </div>

          </div> */}
        <div
          className={`collapse navbar-collapse justify-content-end ${
            isNavbarOpen ? "show" : ""
          }`}
          id="navbarSupportedContent"
        >
          <div className={` ${styles.navbar_btn}`}>
            {loginUserData ? (
              <ul
                className={`${styles.navbar_tab} navbar-nav align-items-lg-center`}
              >
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/aboutUs">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/quizzes"
                  >
                    Quizzes
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" aria-current="page" to="/faqs">
                    FAQs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/testimonials"
                  >
                    Testimonials
                  </NavLink>
                </li>
                <li className="nav-item pe-2">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/contact"
                  >
                    Contact
                  </NavLink>
                </li>
                {!shouldHideButtons ? (
                  <div className="d-lg-flex">
                    {cartItemsCount !== "0" ? (
                      <Button
                        type="button"
                        label="Cart"
                        icon="pi pi-shopping-cart"
                        badge={cartItemsCount}
                        badgeClassName="p-badge-danger"
                        className={`bg-white border-0 ${styles.cartbtn} ${
                          location.pathname === "/cart"
                            ? styles.activeButton
                            : ""
                        }`}
                        onClick={() => navigate("/cart")}
                      />
                    ) : (
                      <Button
                        type="button"
                        label="Cart"
                        icon="pi pi-shopping-cart"
                        badgeClassName="p-badge-danger"
                        className={`bg-white border-0 ${styles.cartbtn} ${
                          location.pathname === "/cart"
                            ? styles.activeButton
                            : ""
                        }`}
                        onClick={() => navigate("/cart")}
                      />
                    )}
                    <div className="d-flex">
                      <div className="mx-xl-2">
                        <div>
                          <strong>Welcome! </strong>
                        </div>
                        <span>{firstName}</span>
                        <span className="ps-1">{lastName}</span>
                      </div>
                      <Dropdown
                        value={selectedOption}
                        options={dropdownOptions}
                        onChange={(e)=>onDropdownChange(e)}
                        placeholder={
                          <div
                            style={{
                              width: "35px",
                              backgroundColor: initialsColor,
                              borderRadius: "50%",
                              padding: "6px",
                              color: "white",
                            }}
                            className="d-flex justify-content-center"
                          >
                            {initials}
                          </div>
                        }
                        valueTemplate={
                          selectedOption && (
                            <div
                              style={{
                                width: "35px",
                                backgroundColor: initialsColor,
                                borderRadius: "50%",
                                padding: "6px",
                                color: "white",
                              }}
                              className="d-flex justify-content-center"
                            >
                              {initials}
                            </div>
                          )
                        }
                        style={{ border: "none" }}
                        className="d-flex justify-content-center align-items-center text-white overflow-hidden"
                      />
                    </div>
                  </div>
                ) : null}
              </ul>
            ) : (
              <div className={`mt-lg-0 ${styles.home_buttons} d-flex`}>
                <ul
                  className={`${styles.navbar_tab} navbar-nav align-items-lg-center`}
                >
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/aboutUs">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/quizzes"
                    >
                      Quizzes
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/faqs"
                    >
                      FAQs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/testimonials"
                    >
                      Testimonials
                    </NavLink>
                  </li>
                  <li className="nav-item pe-2">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/contact"
                    >
                      Contact
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/Signup"
                    >
                      Sign Up
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/Login"
                    >
                      Login
                    </NavLink>
                  </li>
                  {/* <Button
                    type="button"
                    label="Sign Up"
                    className={`${styles.navbar_signup} ${location.pathname === '/Signup' ? styles.activeButton : ''}`}
                    onClick={() => navigate("/Signup")}
                  />
                  <Button
                    type="button"
                    label="Login"
                    className={`${styles.navbar_signup} ${location.pathname === '/Login' ? styles.activeButton : ''}`}
                    onClick={() => navigate("/Login")}
                  /> */}
                  {!shouldHideButtons ? (
                    <div className="d-lg-flex">
                      <div>
                        {cartItemsCount !== "0" ? (
                          <Button
                            type="button"
                            label="Cart"
                            icon="pi pi-shopping-cart"
                            badge={cartItemsCount}
                            badgeClassName="p-badge-danger"
                            className={`bg-white border-0 ${styles.cartbtn} ${
                              location.pathname === "/cart"
                                ? styles.activeButton
                                : ""
                            }`}
                            style={{ padding: "15px" }}
                            onClick={() => navigate("/cart")}
                          />
                        ) : (
                          <Button
                            type="button"
                            label="Cart"
                            icon="pi pi-shopping-cart"
                            badgeClassName="p-badge-danger"
                            className={`bg-white border-0 ${styles.cartbtn} ${
                              location.pathname === "/cart"
                                ? styles.activeButton
                                : ""
                            }`}
                            style={{ padding: "15px" }}
                            onClick={() => navigate("/cart")}
                          />
                        )}
                      </div>

                      <Button
                        type="button"
                        className={`${styles.free_btn}`}
                        onClick={() => {
                          navigateToFreeQuiz(freeQuizId);
                        }}
                      >
                        Try Out For Free
                      </Button>
                    </div>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
