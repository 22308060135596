import { api } from "../api/api";

export const getSubTopics = async (topicId) => {
    try {
      const response = await api.get(`api/Topics?topicId=${topicId}`);
      return response?.data?.result || [];
    } catch (error) {
      throw new Error(error?.response?.data?.title);
    }
  };
  