import React, { useEffect, useRef, useState } from "react";
import CardComponent from "../../components/Card/CardComponent";
import TagComponent from "../../components/Tag/TagComponent";
import styles from "./topicCard.module.scss";
import { Dropdown } from "primereact/dropdown";
import PaginationComponent from "../../components/Pagination/PaginationComponent";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const SubTopicCard = (props) => {
  const navigate = useNavigate();
  const { topicDetails } = props;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(4);
  const [expandedSubtopics, setExpandedSubtopics] = useState({});
  const [showReadMore, setShowReadMore] = useState({});
  const descriptionRefs = useRef([]);
  const totalRecords = topicDetails.length;

  const checkOverflow = (subtopic, index) => {
    const descriptionRef = descriptionRefs.current[index];
    if (descriptionRef) {
      const { scrollHeight, clientHeight } = descriptionRef;
      return scrollHeight > clientHeight;
    }
    return false;
  };

  useEffect(() => {
    const handleResize = () => {
      topicDetails.forEach((subtopic, index) => {
        const isOverflowing = checkOverflow(subtopic, index);
        setShowReadMore((prev) => ({
          ...prev,
          [subtopic.quizId]: isOverflowing,
        }));
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [topicDetails]);

  const price = [
    { name: "One Month", code: 1 },
    { name: "Three Months", code: 2 },
    { name: "One Year", code: 3 },
  ];

  if (topicDetails?.length > 0) {
    topicDetails.map((item) => {
      if (item?.addedSubscription) {
        item.selectedPlan = item?.addedSubscription;
        const price =
          item?.addedSubscription?.code == 1
            ? item?.quizPricePerMonth
            : item?.addedSubscription?.code == 2
            ? item?.quizPricePerQuarter
            : item?.quizPricePerAnum;
        item.selectedPlanPrice = price;
      } else {
        item.selectedPlan = { name: "One Month", code: 1 };
        item.selectedPlanPrice = item?.quizPricePerMonth;
      }
    });
  }

  const handlePageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  const currentPageSubtopics = topicDetails.slice(first, first + rows);

  const handleSubscription = (e, subtopic) => {
    props.handleSubscriptionPlan(e, subtopic?.quizId);
  };

  const toggleReadMore = (subtopic) => {
    setExpandedSubtopics((prev) => ({
      ...prev,
      [subtopic.quizId]: !prev[subtopic.quizId],
    }));
  };
  const handleCart = (subtopic) => {
    props?.handleSubTopicCart(subtopic?.quizId);
  };
  const navigateToCart = () => {
    navigate("/cart");
  };
  const navigateToQuiz = (quizId) => {
    navigate(`/Quiz/${quizId}`);
  };
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    // String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  return (
    <div>
      <div>
        <div
          className={`${styles.bundle_heading} mt-4 text-white text-center mb-4`}
        >
          <h4 className="font_text medium mb-0">
            Or buy what you need from below à la carte
          </h4>
        </div>
        <div className="row">
          {currentPageSubtopics?.map((subtopic, index) => {
            const formattedDate = formatDate(subtopic?.expiryDate);

            return (
              <div
                key={index}
                className={`col-12 col-lg-6 mb-4 ${styles.card_medium}`}
              >
                <CardComponent
                  key={subtopic.quizId}
                  className={`${styles.customCard}`}
                >
                  <div>
                    <div
                      className={`d-xxl-flex align-items-center justify-content-between ${styles.tag_component} mb-2`}
                    >
                      <div className="d-xxl-flex align-items-center">
                        <TagComponent
                          content={`${subtopic.numberOfQuestions} Questions/Quiz`}
                          className={`${styles.question_no} d-flex justify-content-center align-items-center me-2 px-1 mt-2`}
                        />
                        <TagComponent
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              style={{ paddingRight: "2px" }}
                            >
                              <path d="M6 1.33301H10Z" fill="#22C55E" />
                              <path
                                d="M6 1.33301H10"
                                stroke="#166534"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8 6.66699V9.33366"
                                stroke="#166534"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.00008 14.6667C10.9456 14.6667 13.3334 12.2789 13.3334 9.33333C13.3334 6.38781 10.9456 4 8.00008 4C5.05456 4 2.66675 6.38781 2.66675 9.33333C2.66675 12.2789 5.05456 14.6667 8.00008 14.6667Z"
                                stroke="#166534"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                          content={`${subtopic.quizTimeLimit} Min`}
                          className={`${styles.time_limit} d-flex justify-content-center align-items-center me-2 px-1 mt-2`}
                        />
                      </div>
                      <div>
                        <TagComponent
                          content={`${subtopic.totalQuestionsinPool}`}
                          className={`${styles.pool_questions} d-flex justify-content-center align-items-center me-2 px-1 mt-2`}
                        />
                      </div>
                    </div>
                    <div>
                      <p
                        className={`${styles.topicbundle_header} font_text medium mb-1`}
                      >
                        {subtopic?.quizName}
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <p
                      ref={(el) => (descriptionRefs.current[index] = el)}
                      className={`${styles.topicbundle_description} ${
                      expandedSubtopics[subtopic.quizId] ? "" : styles.truncated
                      } m-0`}>
                      {subtopic?.quizDescription}
                    </p>
                    {showReadMore[subtopic.quizId] && (
                      <Button
                        label={expandedSubtopics[subtopic.quizId] ? "Read Less" : "Read More"}
                        link
                        onClick={() => toggleReadMore(subtopic)}
                        className={`p-0 ${styles.toggleBtn}`}
                      />
                    )}
                  </div>
                  <div className="d-md-flex justify-content-between mt-4 ">
                    {subtopic?.isPurchased === true ? (
                      <div>
                        <p className="m-0">
                          <strong>Expiry Date: {formattedDate}</strong>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p
                          className={`text-dark fw-small ${styles.subscriptionLabel}`}
                        >
                          Choose Subscription
                        </p>
                        <div className="d-flex align-items-center">
                          <Dropdown
                            value={subtopic?.selectedPlan}
                            onChange={(e) => handleSubscription(e, subtopic)}
                            options={price}
                            optionLabel="name"
                            placeholder="select"
                            className={`${styles.customDropdown}`}
                          />
                          <div
                            className={`${styles.dollar_svg} h-100 d-flex align-items-center`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2056_97855)">
                                <path
                                  d="M10 0.833008V19.1663"
                                  stroke="#0F172A"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.1667 4.16699H7.91667C7.14312 4.16699 6.40125 4.47428 5.85427 5.02126C5.30729 5.56825 5 6.31011 5 7.08366C5 7.85721 5.30729 8.59907 5.85427 9.14605C6.40125 9.69303 7.14312 10.0003 7.91667 10.0003H12.0833C12.8569 10.0003 13.5987 10.3076 14.1457 10.8546C14.6927 11.4016 15 12.1434 15 12.917C15 13.6905 14.6927 14.4324 14.1457 14.9794C13.5987 15.5264 12.8569 15.8337 12.0833 15.8337H5"
                                  stroke="#0F172A"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2056_97855">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div
                            className={`${styles.topic_price_amount} h-100 d-flex align-items-center`}
                          >
                            <h4
                              className={`${styles.topic_price} font_text semibold m-0`}
                            >
                              {subtopic.selectedPlanPrice}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={`${styles.domain_btn}  pt-3 pt-md-0 d-flex align-items-end `}
                    >
                      {subtopic?.addToCart === true &&
                        !subtopic?.isPurchased && (
                          <button
                            className={`${styles?.primaryBtn} text-white d-flex align-items-center px-3 py-2 mt-sm-1 mt-md-0`}
                            onClick={navigateToCart}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M6.125 14.292C5.87777 14.292 5.6361 14.2187 5.43054 14.0813C5.22498 13.944 5.06476 13.7488 4.97015 13.5203C4.87554 13.2919 4.85079 13.0406 4.89902 12.7981C4.94725 12.5557 5.0663 12.3329 5.24112 12.1581C5.41593 11.9833 5.63866 11.8642 5.88114 11.816C6.12361 11.7678 6.37495 11.7925 6.60336 11.8871C6.83176 11.9818 7.02699 12.142 7.16434 12.3475C7.30169 12.5531 7.375 12.7948 7.375 13.042C7.375 13.3735 7.2433 13.6915 7.00888 13.9259C6.77446 14.1603 6.45652 14.292 6.125 14.292Z"
                                fill="white"
                              />

                              <path
                                d="M11.5415 14.292C11.2943 14.292 11.0526 14.2187 10.847 14.0813C10.6415 13.944 10.4813 13.7488 10.3867 13.5203C10.292 13.2919 10.2673 13.0406 10.3155 12.7981C10.3638 12.5557 10.4828 12.3329 10.6576 12.1581C10.8324 11.9833 11.0552 11.8642 11.2976 11.816C11.5401 11.7678 11.7915 11.7925 12.0199 11.8871C12.2483 11.9818 12.4435 12.142 12.5808 12.3475C12.7182 12.5531 12.7915 12.7948 12.7915 13.042C12.7915 13.3735 12.6598 13.6915 12.4254 13.9259C12.191 14.1603 11.873 14.292 11.5415 14.292Z"
                                fill="white"
                              />

                              <path
                                d="M12.9999 10.9587H4.66659C4.51986 10.9573 4.37815 10.905 4.26568 10.8108C4.15321 10.7166 4.07694 10.5862 4.04992 10.442L2.47492 1.79199H1.33325C1.16749 1.79199 1.00852 1.72614 0.89131 1.60893C0.7741 1.49172 0.708252 1.33275 0.708252 1.16699C0.708252 1.00123 0.7741 0.842261 0.89131 0.72505C1.00852 0.60784 1.16749 0.541992 1.33325 0.541992H2.99992C3.14665 0.543344 3.28835 0.595601 3.40082 0.689836C3.5133 0.78407 3.58956 0.914436 3.61659 1.05866L3.97492 3.04199H14.6666C14.7615 3.04229 14.8551 3.06421 14.9403 3.10608C15.0255 3.14796 15.1 3.20868 15.1583 3.28366C15.2157 3.35851 15.256 3.44511 15.2761 3.5373C15.2963 3.62948 15.2959 3.72498 15.2749 3.81699L13.6083 10.4837C13.5734 10.6188 13.4948 10.7386 13.3848 10.8245C13.2748 10.9104 13.1395 10.9576 12.9999 10.9587V10.9587ZM5.19159 9.70866H12.5083L13.8333 4.29199H4.19992L5.19159 9.70866Z"
                                fill="white"
                              />
                            </svg>
                            {subtopic?.addedToCartLabel != null &&
                            subtopic?.addedToCartLabel != undefined
                              ? subtopic?.addedToCartLabel
                              : "Go to Cart "}
                          </button>
                        )}
                      {!subtopic?.isPurchased && !subtopic?.addToCart && (
                        <button
                          className={` d-flex align-items-center px-3 py-2 text-white ${styles.addcart_btn} mt-sm-1 mt-md-0`}
                          onClick={() => handleCart(subtopic)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                          >
                            <path
                              d="M6.125 14.292C5.87777 14.292 5.6361 14.2187 5.43054 14.0813C5.22498 13.944 5.06476 13.7488 4.97015 13.5203C4.87554 13.2919 4.85079 13.0406 4.89902 12.7981C4.94725 12.5557 5.0663 12.3329 5.24112 12.1581C5.41593 11.9833 5.63866 11.8642 5.88114 11.816C6.12361 11.7678 6.37495 11.7925 6.60336 11.8871C6.83176 11.9818 7.02699 12.142 7.16434 12.3475C7.30169 12.5531 7.375 12.7948 7.375 13.042C7.375 13.3735 7.2433 13.6915 7.00888 13.9259C6.77446 14.1603 6.45652 14.292 6.125 14.292Z"
                              fill="white"
                            />

                            <path
                              d="M11.5415 14.292C11.2943 14.292 11.0526 14.2187 10.847 14.0813C10.6415 13.944 10.4813 13.7488 10.3867 13.5203C10.292 13.2919 10.2673 13.0406 10.3155 12.7981C10.3638 12.5557 10.4828 12.3329 10.6576 12.1581C10.8324 11.9833 11.0552 11.8642 11.2976 11.816C11.5401 11.7678 11.7915 11.7925 12.0199 11.8871C12.2483 11.9818 12.4435 12.142 12.5808 12.3475C12.7182 12.5531 12.7915 12.7948 12.7915 13.042C12.7915 13.3735 12.6598 13.6915 12.4254 13.9259C12.191 14.1603 11.873 14.292 11.5415 14.292Z"
                              fill="white"
                            />

                            <path
                              d="M12.9999 10.9587H4.66659C4.51986 10.9573 4.37815 10.905 4.26568 10.8108C4.15321 10.7166 4.07694 10.5862 4.04992 10.442L2.47492 1.79199H1.33325C1.16749 1.79199 1.00852 1.72614 0.89131 1.60893C0.7741 1.49172 0.708252 1.33275 0.708252 1.16699C0.708252 1.00123 0.7741 0.842261 0.89131 0.72505C1.00852 0.60784 1.16749 0.541992 1.33325 0.541992H2.99992C3.14665 0.543344 3.28835 0.595601 3.40082 0.689836C3.5133 0.78407 3.58956 0.914436 3.61659 1.05866L3.97492 3.04199H14.6666C14.7615 3.04229 14.8551 3.06421 14.9403 3.10608C15.0255 3.14796 15.1 3.20868 15.1583 3.28366C15.2157 3.35851 15.256 3.44511 15.2761 3.5373C15.2963 3.62948 15.2959 3.72498 15.2749 3.81699L13.6083 10.4837C13.5734 10.6188 13.4948 10.7386 13.3848 10.8245C13.2748 10.9104 13.1395 10.9576 12.9999 10.9587V10.9587ZM5.19159 9.70866H12.5083L13.8333 4.29199H4.19992L5.19159 9.70866Z"
                              fill="white"
                            />
                          </svg>
                          Add to Cart
                        </button>
                      )}
                      {subtopic?.isPurchased && !subtopic?.addToCart && (
                        <button
                          className={` d-flex align-items-center px-3 py-2 text-dark ${styles.takeQuiz} mt-sm-1 mt-md-0`}
                          onClick={() => navigateToQuiz(subtopic?.quizId)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="15"
                            viewBox="0 0 16 15"
                            fill="none"
                          >
                            <path
                              d="M6.125 14.292C5.87777 14.292 5.6361 14.2187 5.43054 14.0813C5.22498 13.944 5.06476 13.7488 4.97015 13.5203C4.87554 13.2919 4.85079 13.0406 4.89902 12.7981C4.94725 12.5557 5.0663 12.3329 5.24112 12.1581C5.41593 11.9833 5.63866 11.8642 5.88114 11.816C6.12361 11.7678 6.37495 11.7925 6.60336 11.8871C6.83176 11.9818 7.02699 12.142 7.16434 12.3475C7.30169 12.5531 7.375 12.7948 7.375 13.042C7.375 13.3735 7.2433 13.6915 7.00888 13.9259C6.77446 14.1603 6.45652 14.292 6.125 14.292Z"
                              fill="black"
                            />

                            <path
                              d="M11.5415 14.292C11.2943 14.292 11.0526 14.2187 10.847 14.0813C10.6415 13.944 10.4813 13.7488 10.3867 13.5203C10.292 13.2919 10.2673 13.0406 10.3155 12.7981C10.3638 12.5557 10.4828 12.3329 10.6576 12.1581C10.8324 11.9833 11.0552 11.8642 11.2976 11.816C11.5401 11.7678 11.7915 11.7925 12.0199 11.8871C12.2483 11.9818 12.4435 12.142 12.5808 12.3475C12.7182 12.5531 12.7915 12.7948 12.7915 13.042C12.7915 13.3735 12.6598 13.6915 12.4254 13.9259C12.191 14.1603 11.873 14.292 11.5415 14.292Z"
                              fill="black"
                            />

                            <path
                              d="M12.9999 10.9587H4.66659C4.51986 10.9573 4.37815 10.905 4.26568 10.8108C4.15321 10.7166 4.07694 10.5862 4.04992 10.442L2.47492 1.79199H1.33325C1.16749 1.79199 1.00852 1.72614 0.89131 1.60893C0.7741 1.49172 0.708252 1.33275 0.708252 1.16699C0.708252 1.00123 0.7741 0.842261 0.89131 0.72505C1.00852 0.60784 1.16749 0.541992 1.33325 0.541992H2.99992C3.14665 0.543344 3.28835 0.595601 3.40082 0.689836C3.5133 0.78407 3.58956 0.914436 3.61659 1.05866L3.97492 3.04199H14.6666C14.7615 3.04229 14.8551 3.06421 14.9403 3.10608C15.0255 3.14796 15.1 3.20868 15.1583 3.28366C15.2157 3.35851 15.256 3.44511 15.2761 3.5373C15.2963 3.62948 15.2959 3.72498 15.2749 3.81699L13.6083 10.4837C13.5734 10.6188 13.4948 10.7386 13.3848 10.8245C13.2748 10.9104 13.1395 10.9576 12.9999 10.9587V10.9587ZM5.19159 9.70866H12.5083L13.8333 4.29199H4.19992L5.19159 9.70866Z"
                              fill="black"
                            />
                          </svg>
                          Take Quiz
                        </button>
                      )}
                    </div>
                  </div>
                </CardComponent>
              </div>
            );
          })}
          <div className="d-md-flex justify-content-end align-items-center">
            <div className={`text-center ${styles.record_count}`}>
              <p className="m-0 p-1">
                Showing 1 to 4 of {totalRecords} entries
              </p>
            </div>
            <PaginationComponent
              className={styles.topiccardpagination}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubTopicCard;
