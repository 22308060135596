import {api} from "../api/api"

export const getFirstQuestion =  async (id,levelId) => {
    try {
      const response = await api.post(`api/Quizzes/${id}/Start?difficultyLevelId=${levelId}`)
      return response.data.result || [];
    }
    catch (error) {
        throw error?.response?.data;
    }
  }

export const fetchNextQuestion = async (id,questionId,userQuizId,answers) => {
  try {
    const response = await api.post(`api/Quizzes/${id}/Question/${questionId}/Next?userQuizId=${userQuizId}`, answers)
    return response.data.result || [];
  }
  catch (error) {
      throw new Error(error?.response?.data?.title);
  }
}

export const fetchPrevQuestion = async (id,questionId,userQuizId) => {
  try {
    const response = await api.get(`api/Quizzes/${id}/Question/${questionId}/Previous?userQuizId=${userQuizId}`)
    return response.data.result || [];
  }
  catch (error) {
      throw new Error(error?.response?.data?.title);
  }
}

export const submitQuiz = async (id,userQuizId,formattedTimeTaken) =>{
  try{
    const response = await api.post(`api/Quizzes/${id}/Submit?userQuizId=${userQuizId}&endTime=${formattedTimeTaken}`)
    return response.data.result || [];
  }
  catch(error){
    throw new Error(error?.response?.data?.title);
  }
}

export const  getAnswers = async (id,userQuizId)=>{
  try{
    const response = await api.get(`api/Quizzes/${id}/Result?userQuizId=${userQuizId}`)
    return response.data.result || [];
  }
  catch(error){
    throw new Error(error?.response?.data?.title);
  }
}
