import { api } from "../api/api";

const sendEmail = async (email) => {
    try {
        const response = await api.post(`/api/User/ForgotPassword/SendOTP?email=${encodeURIComponent(email)}`)
        return response?.data?.result || [];
    }
    catch (error) {
        throw new Error(error?.response?.data?.title);
    }
}

const otpVerification = async (otpEmail, otpValue) => {
    try {
        const response = await api.post(`/api/UserOtp/Verify`, { email: otpEmail, otp: otpValue })
        return response?.data?.result || []
    } catch (error) {
        throw new Error(error?.response?.data?.responseException?.message);

    }
}

const passwordUpdation = async (otp, values) => {
    try {
        const response = await api.put(`/api/User/Password?otp=${otp}`, {
            password: values.newPassword,
            confirmPassword: values.repeatPassword
        })
    } catch (error) {
        throw new Error(error?.response?.data?.title);
    }
}
export { sendEmail, otpVerification,passwordUpdation }