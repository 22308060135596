import React, { useEffect, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import styles from './myAccount.module.scss'
import EditProfile from '../editProfile/editProfile';
import OrderDetails from '../orderDetails/orderDetails';
import MyQuiz from '../myQuizzes/myQuiz';
import QuizHistory from '../quizHistory/quizHistory';

const MyAccount = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const savedIndex = localStorage.getItem('activeTabIndex');
        if (savedIndex !== null) {
            setActiveIndex(parseInt(savedIndex, 10)); 
        }
    }, []);
    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        localStorage.setItem('activeTabIndex', e.index);
    };
    return (
        <div className='container-fluid'>
            <div className='px-lg-5 mx-4 py-3'>
                <div className={`${styles.tabview}`}>
                    <h4 className='mt-2 mb-4'>MY ACCOUNT</h4>
                </div>
                <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                    <TabPanel header="Account Information" className='text-decoration-none'>
                        <EditProfile />
                    </TabPanel>
                    <TabPanel header="Order History">
                        <OrderDetails />
                    </TabPanel>
                    <TabPanel header="My Quizzes">
                        <MyQuiz/>
                    </TabPanel>
                    <TabPanel header="Quiz History">
                        <QuizHistory/>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}

export default MyAccount