import React from "react";
import Brief from "./homePageBrief/brief";
import Domain from "./domain/domain";
import About from "./about-section/about";
import Content from "./homePageContent/content";

const Home = () => {
  return (
    <div>
      <Brief />
      <Domain />
      <About />
      <Content />
    </div>
  );
};

export default Home;
