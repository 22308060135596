import React from "react";
import Figure from "../../../assets/images/homeScreenTile3.png";
import styles from "./content.module.scss";

const Content = () => {
  const points = [
    "Enhance your knowledge",
    "Demonstrate competence",
    "Build confidence",
  ];
  return (
    <div className={`${styles.home_content_grid} pt-5`}>
      <section className="container-fluid">
        <div className="px-lg-4 mx-4">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-7">
            <div className="py-3">
              {points.map((point, index) => {
                return (
                  <div className={`d-flex ${styles.content} gucina-regular m-auto mt-4 bg-white p-3`} key={index}>
                    <div className={`${styles.content_check} me-3`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        {" "}
                        <path
                          d="M10.5 18.75L15 23.25L25.5 12.75"
                          stroke="#22C55E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />{" "}
                        <path
                          d="M18 33C26.2842 33 33 26.2842 33 18C33 9.71572 26.2842 3 18 3C9.71572 3 3 9.71572 3 18C3 26.2842 9.71572 33 18 33Z"
                          stroke="#16A34A"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />{" "}
                      </svg>
                    </div>
                    <h4 className="font_text bold pt-2">{point}</h4>
                  </div>
                );
              })}
              <div className={`mt-4 ${styles.quiz_end}`}>
                <h3 className="font_text bold text-center">A quiz a day - goes a long way</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 col-xl-5">
            <div className="h-100 d-flex justify-content-center">
              <img src={Figure} className="img-fluid" alt="no img"/>
            </div>
          </div>
        </div>
        </div>
       
      </section>
    </div>
  );
};

export default Content;
