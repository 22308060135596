import React, { useCallback, useEffect, useState } from "react";
import CardComponent from "../../components/Card/CardComponent";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import styles from "./Quiz.module.scss";

const QuizQuestions = (props) => {
  const { questionDetails, questionNo, totalQuestions, selectedOptions } =
    props;
  const [answersChecked, setAnswersChecked] = useState(selectedOptions || []);
  const [checkedState, setCheckedState] = useState(
    new Array(questionDetails[0]?.answers?.length).fill(false)
  );
  const [radioState, setRadioState] = useState(() =>
    questionDetails.length > 0
      ? new Array(questionDetails[0]?.answers?.length).fill(false)
      : []
  );
  const [ingredient, setIngredient] = useState("");

  const handleNextQuestion = (nextOrPrev) => {
    props?.nextQuestion(nextOrPrev, answersChecked);
  };

  const handleCheckboxChange = (position, option) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    if (answersChecked.includes(option?.questionAnswerId)) {
      setAnswersChecked(
        answersChecked.filter((id) => id !== option?.questionAnswerId)
      );
    } else {
      setAnswersChecked([...answersChecked, option?.questionAnswerId]);
    }

    // setAnswersChecked([...answersChecked,option?.questionAnswerId])
    // console.log(answersChecked)
  };
  const handleRadioChange = useCallback((position, option) => {
    const updatedRadioState = radioState.map((item, index) =>
      index === position ? true : false
    );
      setRadioState(updatedRadioState);
      setAnswersChecked([option?.questionAnswerId]);
      
    },[radioState,setAnswersChecked]);
   

  useEffect(() => {
    const newCheckedState = new Array(questionDetails[0]?.answers?.length).fill(
      false
    );
    const newRadioState = new Array(questionDetails[0]?.answers?.length).fill(
      false
    );

    questionDetails[0]?.answers?.forEach((answer, index) => {
      if (selectedOptions.includes(answer?.questionAnswerId)) {
        newCheckedState[index] = true;
        newRadioState[index] = true;
      }
    });

    setCheckedState(newCheckedState);
    setRadioState(newRadioState);
  }, [selectedOptions, questionDetails]);

  return (
    <CardComponent>
      <div className={styles.quiz_Container}>
        <div>
          <span className={`${styles.question_number} font-text`}>
            Question {questionNo}:
          </span>
          <span className={`${styles.quention} font-text`}>
            {questionDetails[0]?.question}
          </span>
        </div>
        {questionDetails[0]?.questionImage && (
          <div className={`mt-3 ${styles.questionImg}`} style={{width:"30%"}}>
            <img
              src={questionDetails[0]?.questionImage}
              alt={questionDetails[0]?.questionImage}
              width="100%"
            />
          </div>
        )}
        <div className="pt-3">
          {questionDetails[0]?.answers?.map((option, index) => (
            <div
              className="d-flex align-items-center justify-content-start p-2"
              key={option?.questionAnswerId}
            >
              {questionDetails[0]?.questionType === "CheckBox" && (
                <Checkbox
                  name="optionCheck"
                  onChange={() => handleCheckboxChange(index, option)}
                  checked={checkedState[index]}
                ></Checkbox>
              )}
              {questionDetails[0]?.questionType === "RadioButton" && (
                <RadioButton
                  name="optionRadio"
                  onChange={() => handleRadioChange(index, option)}
                  checked={radioState[index]}
                />
              )}
              <p className={` ${styles.optionText} mb-0`}>{option?.answer}</p>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <Button
            label="Previous"
            onClick={() => handleNextQuestion("prev")}
            className={`${styles.prevBtn} ${styles.Btn}`}
            disabled={questionNo === 1}
          />
          {questionNo != totalQuestions ? (
            <Button
              label="Next"
              onClick={() => handleNextQuestion("next")}
              className={`${styles.nextBtn} ${styles.Btn}`}
            />
          ) : (
            <Button
              label="Submit"
              onClick={() => handleNextQuestion("submit")}
              className={`${styles.nextBtn} ${styles.Btn}`}
            />
          )}
        </div>
      </div>
    </CardComponent>
  );
};

export default React.memo(QuizQuestions);
