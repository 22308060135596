import { Button } from "primereact/button";

const showSubTopicCount = (rowData, styles, setShowDialog, setSubTopicList) => {
  const freeQuiz = rowData?.children?.filter((item) => item?.quizId === 1);
  const count = freeQuiz
    ? rowData?.children?.length - 1
    : rowData?.children?.length;
  return (
    <p style={{ margin: "0px" }}>
      {rowData?.isBundle === true ? (
        <a
          className={styles?.link}
          onClick={() => openDialog(rowData, setShowDialog, setSubTopicList)}
        >
          View ({count})
        </a>
      ) : (
        rowData?.quizName
      )}
    </p>
  );
};

const openDialog = (rowData, setShowDialog, setSubTopicList) => {
  setShowDialog(true);
  const data = rowData?.children.map((item) => ({
    quizName: item?.quizName,
    quizId: item?.quizId,
    quizTypeId: item?.quizTypeId,
  }));
  setSubTopicList(data);
};
const deleteCartItems = (rowData, deleteItem) => {
  return (
    <Button
      icon="pi pi-trash"
      severity="danger"
      aria-label="Delete"
      onClick={() => deleteItem(rowData)}
      style={{ color: "red" }}
    />
  );
};

const showOrderSubTopicCount = (
  rowData,
  styles,
  setShowDialog,
  setSubTopicList
) => {
  const subtopics = rowData?.subtopics || [];
  const count = subtopics.length;

  return (
    <p style={{ margin: "0px" }}>
      {rowData?.isBundle ? (
        <a
          className={styles?.link}
          onClick={() =>
            openOrderDialog(rowData, setShowDialog, setSubTopicList)
          }
        >
          View ({count})
        </a>
      ) : (
        subtopics
      )}
    </p>
  );
};

const openOrderDialog = (rowData, setShowDialog, setSubTopicList) => {
  setShowDialog(true);
  setSubTopicList(rowData?.subtopics || []);
};

const showQuizAttempts = (
  rowData,
  styles,
  setShowDialog,
  setQuizAttemptsList
) => {
  const attempts = rowData?.certificate || [];
  const count = attempts.length;
  return (
    <p style={{ margin: "0px" }}>
      {rowData && (
        <a
          className={styles?.link}
          onClick={() =>
            openQuizAttemptsDialog(rowData, setShowDialog, setQuizAttemptsList)
          }
        >
          View ({count})
        </a>
      )}
    </p>
  );
};
const openQuizAttemptsDialog = (
  rowData,
  setShowDialog,
  setQuizAttemptsList
) => {
  setShowDialog(true);
  setQuizAttemptsList(rowData?.certificate || []);
};

export const cartDataColums = (
  styles,
  setShowDialog,
  setSubTopicList,
  deleteItem,
  id
) => {
  if (id !== undefined && id !== null) {
    return [
      { field: "domainName", header: "Domain", width: "15%" },
      {
        field: "TopicName",
        header: "Topic",
        width: "25%",
        minWidth: "",
      },
      {
        field: "quizName",
        header: "Subtopic",
        width: "25%",
        minWidth: "2rem",
        columnType: "fun",
        columnDefination: (rowData) =>
          showSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
      },
      {
        field: "addedSubscription.name",
        header: "Subscription",
        minWidth: "4rem",
      },
      { field: "expiryDate", header: "Expiry Date", minWidth: "4rem" },
    ];
  } else {
    return [
      { field: "domainName", header: "Domain", width: "20%" },
      {
        field: "TopicName",
        header: "Topic",
        width: "25%",
        minWidth: "",
      },
      {
        field: "quizName",
        header: "Subtopic",
        minWidth: "2rem",
        width: "25%",
        columnType: "fun",
        columnDefination: (rowData) =>
          showSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
      },
      {
        field: "addedSubscription.name",
        header: "Subscription",
        width: "15%",
        minWidth: "4rem",
      },
      {
        field: "expiryDate",
        header: "Expiry Date",
        width: "18%",
        minWidth: "20%",
      },
      {
        field: "selectedPlanPrice",
        header: "Price($)",
        width: "18%",
        minWidth: "2rem",
      },
      {
        field: "function",
        header: "",
        width: "1rem",
        minWidth: "2rem",
        sort: false,
        columnType: "fun",
        columnDefination: (rowData) => deleteCartItems(rowData, deleteItem),
      },
    ];
  }
};

export const orderDetailsColums = () => {
  return [
    { field: "orderId", header: "Order#", width: "30%" },
    { field: "date", header: "Date", width: "30%" },
    { field: "paymentStatus", header: "Payment Status", width: "30%" },
    { field: "total", header: "Total($)" },
  ];
};

export const orderDetailsDisplayColumns = (
  styles,
  setShowDialog,
  setSubTopicList
) => {
  return [
    { field: "domain", header: "Domain", width: "20%" },
    { field: "topic", header: "Topic", width: "25%" },
    {
      field: "subtopics",
      header: "Subtopics",
      width: "24%",
      columnType: "fun",
      columnDefination: (rowData) =>
        showOrderSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
    },
    { field: "subscription", header: "Subscription", width: "15%" },
    { field: "expirydate", header: "Expiry Date", width: "25%" },
    { field: "price", header: "Price($)", width: "20%" },
  ];
};

export const myQuizzesColumns = (styles, setShowDialog, setSubTopicList) => {
  return [
    { field: "quizdomain", header: "Domain", width: "15%" },
    { field: "quiztopic", header: "Topic", width: "18%" },
    {
      field: "subtopics",
      header: "Subtopic",
      width: "15%",
      columnType: "fun",
      columnDefination: (rowData) =>
        showOrderSubTopicCount(rowData, styles, setShowDialog, setSubTopicList),
    },
    { field: "subscription", header: "Subscription", width: "15%" },
    { field: "subscriptiondate", header: "Subscription Date", width: "15%" },
    { field: "quizattempt", header: "Total Quiz Attempts", width: "10%" },
    { field: "takequizbtn", header: "", width: "30%" },
  ];
};
export const quizQuestionColumns = () => {
  return [
    { field: "questionId", header: "Question#", width: "10%" },
    { field: "question", header: "Question", width: "80%" },
    { field: "view", width: "10%" },
  ];
};

export const quizHistoryColumns = (
  styles,
  setShowDialog,
  setQuizAttemptsList
) => {
  return [
    { field: "domain", header: "Domain", width: "15%" },
    { field: "topicname", header: "Topic Name", width: "19%" },
    { field: "quizname", header: "Quiz Name", width: "20%" },
    {
      field: "dateoflastattempt",
      header: "Date of Last Attempt",
      width: "13%",
      sort: true,
    },
    {
      field: "highestscore",
      header: "Highest Scrore",
      width: "12%",
      sort: true,
    },
    { field: "totalattempt", header: "Total Quiz Attempts", width: "11%" },
    {
      field: "certificate",
      width: "15%",
      columnType: "fun",
      columnDefination: (rowData) =>
        showQuizAttempts(rowData, styles, setShowDialog, setQuizAttemptsList),
    },
  ];
};

export const quizHistoryDetailsColumn = (superAdminLogin) => {
  const columns = [
    {
      field: "attemptnumber",
      header: "Attempt Number",
      width: "20%",
      sort: true,
    },
    {
      field: "attempteddate",
      header: "Attempted Date",
      width: "20%",
      sort: true,
    },
    { field: "score", header: "Score", width: "15%", sort: true },
    {field:"status",header:"Status",width:"15%"}
  ];
  if (!superAdminLogin) {
    columns.push({
      field: "downloadcertificate",
      header: "Download Certificate",
      width: "20%",
    });
  }

  return columns;
};
