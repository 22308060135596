import React, { useEffect, useState } from "react";
import styles from "./orderDetails.module.scss";
import Table from "../../../components/Table/Table";
import * as constants from "../../../constants/tableConstants";
import { orderDetailsColums } from "../../../components/Table-Columns/TableColumns";
import { Tooltip } from "primereact/tooltip";
import { orderDetails } from "../../../services/orderHistory";
import { Skeleton } from "primereact/skeleton";

const OrderItems = ({ onSelectOrderId }) => {
  const [orderData, setOrderData] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  useEffect(() => {
    setShowSkeleton(true);
    const fetchOrders = async () => {
      try {
        const response = await orderDetails();
        setOrderData(response);
        setShowSkeleton(false);
      } catch (error) {
        setShowSkeleton(true);
        console.error(error?.message);
      }
    };
    fetchOrders();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const getTooltipContent = (status) => {
    if (status === "Pending") {
      return (
        <div>
          <p>
            Payment for this order is still processing. Please check back later.
            If the issue persists, please write to us at:
            <a
              href="mailto:info@quizandteach.com"
              style={{ color: "#0EA5E9", paddingLeft: "5px" }}
            >
              info@quizandteach.com
            </a>
          </p>
        </div>
      );
    } else if (status === "Failed") {
      return (
        <div>
          <p>
            Payment for this order has failed. Please retry or contact our
            support at:
            <a
              href="mailto:info@quizandteach.com"
              style={{ color: "#0EA5E9", paddingLeft: "5px" }}
            >
              info@quizandteach.com
            </a>
          </p>
        </div>
      );
    }
    return null;
  };
  const transformedData = orderData.map((order) => ({
    orderId: (
      <a
        href="#"
        onClick={() => onSelectOrderId(order?.orderId)}
        className="order-id-button text-black"
      >
        {order?.orderId}
      </a>
    ),
    date: formatDate(order?.purchaseDate),
    paymentStatus: (
      <div>
        <span className="me-2">{order?.paymentStatus}</span>
        <span id={order?.paymentStatus.toLowerCase()} data-pr-position="top">
          {" "}
          {order?.paymentStatus === "Pending" && (
            <svg
              xmlns="
            http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_2633_127498)">
                <path
                  d="M8 7.6665V10.9998"
                  stroke="#0EA5E9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 5.00643L8.00667 4.99902"
                  stroke="#0EA5E9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.682 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.682 4.31875 14.6668 8.00065 14.6668Z"
                  stroke="#0EA5E9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2633_127498">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          {order?.paymentStatus === "Failed" && (
            <svg
              xmlns="
            http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_2633_127498)">
                <path
                  d="M8 7.6665V10.9998"
                  stroke="#0EA5E9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 5.00643L8.00667 4.99902"
                  stroke="#0EA5E9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.682 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.682 4.31875 14.6668 8.00065 14.6668Z"
                  stroke="#0EA5E9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2633_127498">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </span>
      </div>
    ),
    total: order?.amount.toFixed(2),
  }));
 
  return (
    <div className={`${styles.orderdetails_content}`}>
      <Tooltip
        id={`pending_tootip`}
        target="#pending"
        style={{ width: "20rem", fontSize: "14px", fontWeight: "500" }}
        autoHide={false}
      >
        {getTooltipContent("Pending")}
      </Tooltip>
      <Tooltip
        id={`failed_tooltip`}
        target="#failed"
        style={{ width: "20rem", fontSize: "14px", fontWeight: "500" }}
        autoHide={false}
      >
        {getTooltipContent("Failed")}
      </Tooltip>
      <h4 className="font_text semibold mt-1 mb-3">Order History</h4>
      {showSkeleton ? (
        <div>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
        </div>
      ) : (
        <div>
          <Table
            content={transformedData}
            rows={constants.defaultRows}
            rowsPerPageOptions={constants.rowsPerPageOptions}
            paginator={false}
            columnsData={orderDetailsColums()}
            scrollable={constants.scrollable}
            emptyMessage={constants.emptyMessage}
            tableStyle={constants.tableStyle}
            currentPageReportTemplate={constants.currentPageReportTemplate}
            paginatorTemplate={constants.paginatorTemplate}
          ></Table>
        </div>
      )}
    </div>
  );
};

export default OrderItems;
