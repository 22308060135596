import { api } from "../api/api";

export const quizUpload = async (formData, categoryId, isFreeQuiz) => {
  try {
    const response = await api.post(
      `/api/Quizzes/Upload?categoryId=${categoryId}&isFreeQuiz=${isFreeQuiz}`,
      formData,
      {
        headers: {
        //   Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export const quizEdit = async (formData, categoryId, isFreeQuiz) => {
  try {
    const response = await api.put(
      `/api/Quizzes/Upload?categoryId=${categoryId}`,
      formData,
      {
        headers: {
        //   Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export const fetchQuizQuestions = async (categoryId) => {
  try {
    const response = await api.get(
      `/api/Quizzes/ViewQuestions?categoryId=${categoryId}`
    );
    return response?.data?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};