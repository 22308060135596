import { api } from "../api/api";

const userRegistration = async (data) => {
    try {
        const response = await api.post(`/api/User/Register`, data)
        return response.data.result || [];
    }
    catch (error) {
        throw new Error(error?.response?.data?.title);
    }
}


const adminRegistration = async (data) => {
    try {
        const response = await api.post(`/api/User/admin`, data)
        return response.data.result || [];
    }
    catch (error) {
        throw new Error(error?.response?.data?.title);
    }
}
export { userRegistration, adminRegistration };