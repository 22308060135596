import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Table = (props) => {
    const {
        content: data,
        columnsData: columns,
        tableStyle,
        paginatorTemplate,
        paginator,
        scrollable,
        rows,
        rowsPerPageOptions,
        emptyMessage,
        rowGroupMode,
        groupRowsBy,
        footerTemplate,
        currentPageReportTemplate,
        headerTemplate,
        styles
      } = props;
  return (
    <>
      <DataTable
        value={data}
        tableStyle={tableStyle}
        paginatorTemplate={paginatorTemplate}
        paginator={paginator}
        rows={rows}
        rowsPerPageOptions={rowsPerPageOptions}
        scrollable={scrollable}
        rowGroupMode={rowGroupMode} 
        groupRowsBy={groupRowsBy}
        rowGroupHeaderTemplate={headerTemplate} 
        className={`${tableStyle.dataTable}`}
        emptyMessage={emptyMessage}
        currentPageReportTemplate={currentPageReportTemplate}
        footer={footerTemplate}
      >
        {columns?.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            sortable={col?.sort}
            frozen={col?.frozen}
            // style={{ width: "20%" }}
            style={{ width: col.width }}
            headerStyle={{ minWidth: col.minWidth }}
            body={
              col?.columnType?.toLocaleLowerCase() === "fun"
                ? col.columnDefination
                : col?.field?.toLocaleLowerCase() === "errors"
                ? props?.createOrderErrorSplit
                : null
            }
          />
        ))}
      </DataTable>
    </>
  );
};

export default Table;
