import React, { useEffect, useRef, useState } from "react";
import styles from "./uploadQuiz.module.scss";
import { Field, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../store/action-creators";
import { bindActionCreators } from "redux";
import * as YUP from "yup";
import ErrorMessage from "../../../components/ErrorMessage";
import { Toast } from "primereact/toast";
import { quizUpload, quizEdit } from "../../../services/uploadQuiz";
import { api } from "../../../api/api";
import { getSubTopics } from "../../../services/subTopicList";

const UploadQuiz = (props) => {
  const { flag } = props;
  const dispatch = useDispatch();
  const toast = useRef(null);
  const fileInputRef = useRef(null);
  const { getAllDomains } = bindActionCreators(actionCreators, dispatch);
  const domains = useSelector((state) => state.domainReducer.alldomains || []);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subtopicLoading, setSubtopicLoading] = useState(false);
  const [dropDownLoading, setDropDownLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCheckBox, setErrorCheckBox] = useState("");
  const [topicSubTopicOptions, setTopicSubTopicOptions] = useState([
    { label: "Select Subtopic", id: null },
  ]);

  let initialValues = {
    domain: "",
    topic: "",
    subTopic: "",
    csvFile: "",
    toggle: false,
  };
  const validationSchema = YUP.object({
    csvFile: YUP.string().required("File is required"),
    domain: YUP.number().required("Domain is required"),
    // topic: YUP.mixed().when(["toggle", "domain"], {
    //   is: (toggle, domain) => !toggle && !domain,
    //   // then: () => YUP.mixed().required("Please select a domain first"),
    //   otherwise: () => YUP.mixed().nullable(),
    // }),
    subTopic: YUP.mixed().nullable(),
    // when(["toggle", "topic", "domain"], {
    //   is: (toggle, topic, domain) =>
    //     !toggle && !domain && (!topic || topic === null),
    //   // then: () => YUP.mixed().required("Please select a topic first"),
    //   otherwise: () => YUP.mixed().nullable(),
    // }),
  });

  useEffect(() => {
    if (domains == undefined || domains == null || domains.length == 0) {
      getAllDomains();
    } else {
      setDropDownLoading(false);
    }
  }, [domains]);
  const handleDomainChange = () => {
    setTopicSubTopicOptions([{ label: "Select Subtopic", id: null }]);
  };
  const topicOptions = domains.flatMap((domain) =>
    (domain.subCategories || []).map((subcategory) => ({
      label: subcategory.name,
      value: subcategory.id,
    }))
  );
  const domainTopicOptions = [
    { label: "Select Topic", id: null },
    ...topicOptions,
  ];

  const fetchSubtopics = async (topicId) => {
    try {
      setSubtopicLoading(true);
      const response = await getSubTopics(topicId);
      const subTopicOptions = response?.map((subtopic) => ({
        label: subtopic?.name,
        value: subtopic?.id,
      }));
      setTopicSubTopicOptions([
        { label: "Select Subtopic", id: null },
        ...subTopicOptions,
      ]);
    } catch (error) {
      setSelectedSubTopic(null);
      setTopicSubTopicOptions([{ label: "Select Subtopic", id: null }]);
      setSubtopicLoading(false);
    } finally {
      setSubtopicLoading(false);
    }
  };
  const handleTopicChange = (e, setFieldValue) => {
    const newValue = e.value;
    if (newValue?.id === null) {
      setSelectedTopic(null);
      setSelectedSubTopic(null);
      setTopicSubTopicOptions([{ label: "Select Subtopic", id: null }]);
      setFieldValue("topic", null);
      setFieldValue("subTopic", null);
    }
    setErrorCheckBox("");
    setErrorMessage("");
  };
  // const subTopicOptions = domains.flatMap((domain) =>
  //   (domain.subCategories || [])
  //     .filter((subcategory) => subcategory.id === selectedTopic)
  //     .flatMap((subcategory) =>
  //       subcategory.subCategories !== null &&
  //       Array.isArray(subcategory.subCategories)
  //         ? subcategory.subCategories.map((subtopic) => ({
  //             label: subtopic.name,
  //             value: subtopic.id,
  //           }))
  //         : []
  //     )
  // );
  // const topicSubTopicOptions = [
  //   { label: "Select Subtopic", id: null },
  //   ...subTopicOptions,
  // ];

  const downloadTemplate = async () => {
    try {
      window
        .open(`${process.env.REACT_APP_BASE_URL}/api/Quizzes/Download/Template`)
        .focus();
    } catch (error) {
      console.log(error);
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    if (files) {
      setErrorMessage("");
    }
  };
  useEffect(() => {
    if (selectedFiles.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }, [selectedFiles]);
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Quiz Uploaded Successfully",
      life: 3000,
    });
  };
  const showError = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const handleSignUp = async (value, { setFieldValue }) => {
    let categoryId;
    if (selectedSubTopic) {
      categoryId = selectedSubTopic;
    } else if (selectedTopic) {
      categoryId = selectedTopic;
    } else if (selectedDomain) {
      categoryId = selectedDomain;
    }
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    if (flag == "create") {
      let isFreeQuiz = value.toggle;
      const { toggle, domain, topic, subTopic } = value;
      if (toggle && (topic !== null || subTopic !== null)) {
        setErrorCheckBox(
          "Free quiz cannot have 'Topic' or 'Subtopic' selected"
        );
        return;
      }
      if (
        toggle === false &&
        selectedDomain !== null &&
        selectedTopic === null &&
        selectedSubTopic === null &&
        selectedFiles.length !== 0
      ) {
        setErrorCheckBox("Tick the checkbox for free quiz upload");
        return;
      }
      try {
        setLoading(true);
        let response = await quizUpload(formData, categoryId, isFreeQuiz);
        showSuccess();
        setSelectedFiles([]);
        setFieldValue("toggle", false);
        setErrorCheckBox("");
      } catch (error) {
        setLoading(false);
        showError(error.message);
        setErrorCheckBox("");
        console.error(error);
      } finally {
        setLoading(false);
        setErrorCheckBox("");
      }
    } else if (flag == "edit") {
      try {
        setLoading(true);
        const response = await quizEdit(formData, categoryId);
        showSuccess();
        setSelectedFiles([]);
      } catch (error) {
        setLoading(false);
        showError(error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const downloadExcelForEdit = () => {
    try {
      setDownloadLoading(true);
      const token = localStorage.getItem("authToken");
      const categoryId = selectedSubTopic
        ? selectedSubTopic
        : selectedTopic
        ? selectedTopic
        : selectedDomain
        ? selectedDomain
        : null;
      if (categoryId !== null && selectedDomain !== null) {
        const url = `${process.env.REACT_APP_BASE_URL}/api/Quizzes/Download/Csv?categoryId=${categoryId}`;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.responseType = "blob";

        xhr.onload = function () {
          if (xhr.status === 200) {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(xhr.response);
            link.download = "file.csv";
            link.click();
          } else if (xhr.status === 401) {
            showError("Your session expired, Please login again");
            setDownloadLoading(false);
          } else {
            showError("Error while downloading the file");
            setDownloadLoading(false);
          }
        };

        xhr.onerror = function () {
          showError("Error while downloading the file");
        };
        xhr.onloadend = function () {
          setDownloadLoading(false);
        };
        xhr.send();
      } else {
        showError("Please select dropdown values to download a file");
        setDownloadLoading(false);
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className={`${styles.instruction} p-3`}>
        <p className="mb-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_2740_15925)">
              <path
                d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z"
                stroke="#605DEC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 13.3333V10"
                stroke="#605DEC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 6.66675H10.0083"
                stroke="#605DEC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2740_15925">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {flag == "create" && (
            <span className="ms-2">Instructions for upload quiz</span>
          )}
          {flag == "edit" && (
            <span className="ms-2">Instructions for update quiz</span>
          )}
        </p>
        {flag == "create" && (
          <ul className="pt-0 mb-0">
            <li>Download the sample template to review the format of quiz.</li>
            <li>
              Select the “Topic” and “Subtopic” then upload your CSV using the
              Browse button. Tick the checkbox ONLY IF this is a free quiz
              option.
            </li>
          </ul>
        )}
        {flag == "edit" && (
          <ul className="pt-0 mb-0">
            <li>
              To update the quiz, first download the existing quiz you wish to
              modify.
            </li>
            <li>
              Make the necessary changes in the csv, and then upload the updated
              quiz.
            </li>
          </ul>
        )}
      </div>
      <div
        className={`d-md-flex justify-content-between ${styles.select_section} mt-4`}
      >
        {flag == "create" && (
          <p>Select Domain, Topic, and Subtopic from below</p>
        )}
        {flag == "edit" && (
          <p>
            Select Domain, Topic, and Subtopic to download the quiz you want to
            update.
          </p>
        )}
        {flag == "create" && (
          <a
            href="#"
            className={`${styles.template} text-decoration-none`}
            onClick={downloadTemplate}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15.2199 17.75H2.77994C2.43316 17.7359 2.09256 17.6535 1.77765 17.5075C1.46274 17.3616 1.17969 17.155 0.944701 16.8996C0.709711 16.6442 0.527393 16.3449 0.408179 16.019C0.288964 15.693 0.235194 15.3468 0.249944 15V12C0.249944 11.8011 0.328961 11.6103 0.469614 11.4697C0.610266 11.329 0.801031 11.25 0.999944 11.25C1.19886 11.25 1.38962 11.329 1.53027 11.4697C1.67093 11.6103 1.74994 11.8011 1.74994 12V15C1.72412 15.2969 1.81359 15.5924 1.99977 15.8251C2.18596 16.0579 2.45459 16.21 2.74994 16.25H15.2199C15.5153 16.21 15.7839 16.0579 15.9701 15.8251C16.1563 15.5924 16.2458 15.2969 16.2199 15V12C16.2199 11.8011 16.299 11.6103 16.4396 11.4697C16.5803 11.329 16.771 11.25 16.9699 11.25C17.1689 11.25 17.3596 11.329 17.5003 11.4697C17.6409 11.6103 17.7199 11.8011 17.7199 12V15C17.7499 15.6954 17.504 16.3744 17.0358 16.8894C16.5676 17.4045 15.915 17.7137 15.2199 17.75Z"
                fill="#0EA5E9"
              />
              <path
                d="M8.99985 12.7501C8.90131 12.7506 8.80367 12.7313 8.71265 12.6936C8.62164 12.6558 8.53909 12.6002 8.46985 12.5301L4.46985 8.53009C4.33737 8.38792 4.26524 8.19987 4.26867 8.00557C4.2721 7.81127 4.35081 7.62588 4.48822 7.48847C4.62564 7.35106 4.81102 7.27234 5.00532 7.26892C5.19963 7.26549 5.38767 7.33761 5.52985 7.47009L8.99985 10.9401L12.4698 7.47009C12.612 7.33761 12.8001 7.26549 12.9944 7.26892C13.1887 7.27234 13.3741 7.35106 13.5115 7.48847C13.6489 7.62588 13.7276 7.81127 13.731 8.00557C13.7344 8.19987 13.6623 8.38792 13.5298 8.53009L9.52985 12.5301C9.4606 12.6002 9.37805 12.6558 9.28704 12.6936C9.19603 12.7313 9.09839 12.7506 8.99985 12.7501Z"
                fill="#0EA5E9"
              />
              <path
                d="M9 12.75C8.80189 12.7474 8.61263 12.6676 8.47253 12.5275C8.33244 12.3874 8.25259 12.1981 8.25 12V1C8.25 0.801088 8.32902 0.610322 8.46967 0.46967C8.61032 0.329018 8.80109 0.25 9 0.25C9.19891 0.25 9.38968 0.329018 9.53033 0.46967C9.67098 0.610322 9.75 0.801088 9.75 1V12C9.74741 12.1981 9.66756 12.3874 9.52747 12.5275C9.38737 12.6676 9.19811 12.7474 9 12.75Z"
                fill="#0EA5E9"
              />
            </svg>
            <span className="ms-2">Download Sample Template</span>
          </a>
        )}
      </div>
      <div className={`${styles.uploadfield}`}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldValue }) => {
            handleSignUp(values, { setFieldValue });
          }}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => (
            <Form name="quizUpload" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div>
                        <label htmlFor="domain">Domain</label>
                      </div>
                      <div>
                        {dropDownLoading ? (
                          <Dropdown
                            loading
                            placeholder="Select Domain"
                            className="w-100"
                          />
                        ) : (
                          <Dropdown
                            id="domain"
                            className="w-100"
                            placeholder="Select Domain"
                            value={selectedDomain}
                            options={domains.map((domain) => ({
                              label: domain.name,
                              value: domain.id,
                            }))}
                            onChange={(e) => {
                              handleDomainChange();
                              setSelectedDomain(e.value);
                              setFieldValue("domain", e.value);
                              setSelectedTopic(null);
                              setSelectedSubTopic(null);
                              setFieldValue("topic", null);
                              setFieldValue("subTopic", null);
                            }}
                          />
                        )}
                      </div>

                      {touched.domain && selectedDomain === null ? (
                        <ErrorMessage>Domain is required</ErrorMessage>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <div>
                        <label htmlFor="topic">Topic</label>
                      </div>
                      {dropDownLoading ? (
                        <Dropdown
                          loading
                          placeholder="Select Topic"
                          className="w-100"
                        />
                      ) : (
                        <Dropdown
                          id="topic"
                          className="w-100"
                          placeholder="Select Topic"
                          value={selectedTopic}
                          options={domainTopicOptions}
                          onChange={(e) => {
                            handleTopicChange(e, setFieldValue);
                            if (e?.value?.id === null) {
                              setSelectedTopic(e?.value?.id);
                              setSelectedSubTopic(null);
                              setFieldValue("topic", e?.value?.id);
                              setFieldValue("subTopic", null);
                              setFieldTouched("topic", true);
                              setErrorCheckBox("");
                              setErrorMessage("");
                            } else {
                              setSelectedTopic(e.value);
                              setSelectedSubTopic(null);
                              setFieldValue("topic", e.value);
                              setFieldValue("subTopic", null);
                              setFieldTouched("topic", true);
                              setErrorCheckBox("");
                              setErrorMessage("");
                              fetchSubtopics(e.value);
                            }
                          }}
                        />
                      )}
                      {selectedTopic !== null && selectedDomain === null ? (
                        <ErrorMessage>Please Select Domain First</ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="row">
                    <div className="col-12 col-md-7 col-lg-8">
                      <div>
                        <label htmlFor="subtopic">Subtopic</label>
                      </div>
                      {dropDownLoading || subtopicLoading ? (
                        <Dropdown
                          loading
                          placeholder="Select Subtopic"
                          className="w-100"
                        />
                      ) : (
                        <Dropdown
                          id="subtopic"
                          className="w-100"
                          placeholder="Select Subtopic"
                          value={selectedSubTopic}
                          options={topicSubTopicOptions}
                          onChange={(e) => {
                            if (e?.value?.id === null) {
                              setSelectedSubTopic(e?.value?.id);
                              setFieldValue("subTopic", e?.value?.id);
                              setFieldTouched("subTopic", true);
                              setErrorCheckBox("");
                            } else {
                              setSelectedSubTopic(e.value);
                              setFieldValue("subTopic", e.value);
                              setFieldTouched("subTopic", true);
                              setErrorCheckBox("");
                              setErrorMessage("");
                            }
                          }}
                        />
                      )}
                      {selectedSubTopic !== null && selectedTopic === null ? (
                        <ErrorMessage>Please Select Topic First</ErrorMessage>
                      ) : null}
                    </div>
                    {flag == "create" && (
                      <div className="col-12 col-sm-4 col-md-5 col-lg-4">
                        <div className={`mt-4 pt-md-3 ${styles.browse}`}>
                          <input
                            id="csvFile"
                            name="csvFile"
                            type="file"
                            accept=".xlsx, .xls, .csv"
                            className="d-none"
                            value={values.csvFile}
                            ref={fileInputRef}
                            onChange={(e) => {
                              handleFileChange(e);
                              handleChange(e);
                            }}
                          />
                          <Button
                            type="button"
                            className="d-flex justify-content-center"
                            onClick={handleButtonClick}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="#ffffff"
                                d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM200 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40zm133.1 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H333.1c-7.2 0-13.1 5.9-13.1 13.1c0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2c0 24.9-20.2 45.1-45.1 45.1H304c-8.8 0-16-7.2-16-16s7.2-16 16-16h42.9c7.2 0 13.1-5.9 13.1-13.1c0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2c0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V368c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V368c0-8.8 7.2-16 16-16z"
                              />
                            </svg>
                            <span className="ps-1">Browse</span>
                          </Button>
                        </div>
                        {errorMessage ? (
                          <ErrorMessage>{errorMessage}</ErrorMessage>
                        ) : touched.csvFile && errors.csvFile ? (
                          <ErrorMessage>{errors.csvFile}</ErrorMessage>
                        ) : null}
                      </div>
                    )}
                    {flag == "edit" && (
                      <div className="col-12 col-sm-4 col-md-5 col-lg-4">
                        <div className={`mt-4 pt-md-3 ${styles.browse}`}>
                          <Button
                            type="button"
                            className="d-flex justify-content-center"
                            onClick={downloadExcelForEdit}
                          >
                            {downloadLoading ? (
                              <div className={`${styles.loader_spinner}`}></div>
                            ) : (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M15.2204 17.75H2.78043C2.43365 17.7359 2.09305 17.6535 1.77814 17.5075C1.46323 17.3616 1.18018 17.155 0.945189 16.8996C0.710199 16.6442 0.527881 16.3449 0.408667 16.019C0.289453 15.693 0.235682 15.3468 0.250432 15V12C0.250432 11.8011 0.32945 11.6103 0.470102 11.4697C0.610754 11.329 0.80152 11.25 1.00043 11.25C1.19934 11.25 1.39011 11.329 1.53076 11.4697C1.67141 11.6103 1.75043 11.8011 1.75043 12V15C1.72461 15.2969 1.81407 15.5924 2.00026 15.8251C2.18645 16.0579 2.45508 16.21 2.75043 16.25H15.2204C15.5158 16.21 15.7844 16.0579 15.9706 15.8251C16.1568 15.5924 16.2463 15.2969 16.2204 15V12C16.2204 11.8011 16.2995 11.6103 16.4401 11.4697C16.5808 11.329 16.7715 11.25 16.9704 11.25C17.1693 11.25 17.3601 11.329 17.5008 11.4697C17.6414 11.6103 17.7204 11.8011 17.7204 12V15C17.7504 15.6954 17.5045 16.3744 17.0363 16.8894C16.5681 17.4045 15.9155 17.7137 15.2204 17.75Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M9.00082 12.7498C8.90228 12.7503 8.80464 12.7311 8.71363 12.6933C8.62262 12.6555 8.54007 12.6 8.47082 12.5298L4.47082 8.52985C4.33834 8.38767 4.26622 8.19963 4.26965 8.00532C4.27308 7.81102 4.35179 7.62564 4.4892 7.48823C4.62661 7.35081 4.812 7.2721 5.0063 7.26867C5.2006 7.26524 5.38865 7.33737 5.53082 7.46985L9.00082 10.9398L12.4708 7.46985C12.613 7.33737 12.801 7.26524 12.9953 7.26867C13.1896 7.2721 13.375 7.35081 13.5124 7.48823C13.6499 7.62564 13.7286 7.81102 13.732 8.00532C13.7354 8.19963 13.6633 8.38767 13.5308 8.52985L9.53082 12.5298C9.46158 12.6 9.37903 12.6555 9.28802 12.6933C9.197 12.7311 9.09936 12.7503 9.00082 12.7498Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M9 12.75C8.80189 12.7474 8.61263 12.6676 8.47253 12.5275C8.33244 12.3874 8.25259 12.1981 8.25 12V1C8.25 0.801088 8.32902 0.610322 8.46967 0.46967C8.61032 0.329018 8.80109 0.25 9 0.25C9.19891 0.25 9.38968 0.329018 9.53033 0.46967C9.67098 0.610322 9.75 0.801088 9.75 1V12C9.74741 12.1981 9.66756 12.3874 9.52747 12.5275C9.38737 12.6676 9.19811 12.7474 9 12.75Z"
                                    fill="white"
                                  />
                                </svg>
                                <span className="ps-1">Download</span>
                              </div>
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {flag == "create" && (
                  <div>
                    {selectedFiles.length > 0 && (
                      <p className="mb-0 my-3">
                        {selectedFiles.map((file, index) => (
                          <p
                            key={index}
                            className={`mb-0 ${styles.filename} d-flex align-items-center`}
                          >
                            <svg
                              xmlns="
                        http://www.w3.org/2000/svg"
                              width="12"
                              height="16"
                              viewBox="0 0 12 16"
                              fill="none"
                            >
                              <path
                                d="M11.0881 5.85347L11.0888 5.85413C11.1095 5.87478 11.1223 5.90201 11.125 5.93102V12.9999C11.125 13.4751 10.9362 13.9308 10.6002 14.2668C10.2642 14.6028 9.80851 14.7916 9.33333 14.7916H2.66667C2.19149 14.7916 1.73577 14.6028 1.39977 14.2668C1.06376 13.9308 0.875 13.4751 0.875 12.9999V2.99992C0.875 2.52474 1.06376 2.06902 1.39977 1.73302C1.73577 1.39702 2.19149 1.20825 2.66667 1.20825H6.39137C6.42446 1.20832 6.45617 1.2215 6.47956 1.24492L6.47978 1.24514L11.0881 5.85347ZM7.39522 2.48803L6.54167 1.63448V2.84159V5.29158V5.79158H7.04167H9.49167H10.6988L9.84522 4.93803L7.39522 2.48803ZM6.29167 1.95825V1.45825H5.79167H2.66667C2.25779 1.45825 1.86566 1.62068 1.57654 1.9098C1.28743 2.19891 1.125 2.59104 1.125 2.99992V12.9999C1.125 13.4088 1.28742 13.8009 1.57654 14.09C1.86566 14.3792 2.25779 14.5416 2.66667 14.5416H9.33333C9.74221 14.5416 10.1343 14.3792 10.4235 14.09C10.7126 13.8009 10.875 13.4088 10.875 12.9999V6.54158V6.04158H10.375H6.42114C6.38714 6.04061 6.35476 6.02668 6.33067 6.00259C6.30657 5.97849 6.29264 5.94612 6.29167 5.91211V1.95825Z"
                                fill="#374151"
                                stroke="#605DEC"
                              />
                            </svg>
                            <span className="ms-2">{file.name}</span>
                          </p>
                        ))}
                      </p>
                    )}
                  </div>
                )}
                {flag == "create" && (
                  <div className="col-12">
                    <div className={`d-flex mt-2 ${styles.tickbox}`}>
                      <Field type="checkbox" name="toggle" disabled={loading} />
                      <p className="mb-0 ps-2">Free quiz</p>
                    </div>
                    <div className="mt-2">
                      {errorCheckBox && (
                        <ErrorMessage>{errorCheckBox}</ErrorMessage>
                      )}
                    </div>
                  </div>
                )}
                {flag == "edit" && (
                  <div className="col-12">
                    <p
                      className={`mt-3 text-dark font-weight-bold ${styles.brwsText}`}
                    >
                      Browse the file that has been updated
                    </p>
                    <input
                      id="csvFile"
                      name="csvFile"
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      className="d-none"
                      value={values.csvFile}
                      ref={fileInputRef}
                      onChange={(e) => {
                        handleFileChange(e);
                        handleChange(e);
                      }}
                    />
                    <Button
                      type="button"
                      className={`d-flex justify-content-center w-100 border border-secondary rounded ${styles.brwsBtnLng}`}
                      onClick={handleButtonClick}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#ffffff"
                          d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM200 352h16c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H200c-22.1 0-40-17.9-40-40V392c0-22.1 17.9-40 40-40zm133.1 0H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H333.1c-7.2 0-13.1 5.9-13.1 13.1c0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2c0 24.9-20.2 45.1-45.1 45.1H304c-8.8 0-16-7.2-16-16s7.2-16 16-16h42.9c7.2 0 13.1-5.9 13.1-13.1c0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2c0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V368c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V368c0-8.8 7.2-16 16-16z"
                        />
                      </svg>
                      <span className="ps-1">Browse</span>
                    </Button>
                    {errorMessage ? (
                      <ErrorMessage>{errorMessage}</ErrorMessage>
                    ) : touched.csvFile && errors.csvFile ? (
                      <ErrorMessage>{errors.csvFile}</ErrorMessage>
                    ) : null}
                  </div>
                )}
                {flag == "edit" && (
                  <div>
                    {selectedFiles.length > 0 && (
                      <p className="mb-0 my-3">
                        {selectedFiles.map((file, index) => (
                          <p
                            key={index}
                            className={`mb-0 ${styles.filename} d-flex align-items-center`}
                          >
                            <svg
                              xmlns="
                        http://www.w3.org/2000/svg"
                              width="12"
                              height="16"
                              viewBox="0 0 12 16"
                              fill="none"
                            >
                              <path
                                d="M11.0881 5.85347L11.0888 5.85413C11.1095 5.87478 11.1223 5.90201 11.125 5.93102V12.9999C11.125 13.4751 10.9362 13.9308 10.6002 14.2668C10.2642 14.6028 9.80851 14.7916 9.33333 14.7916H2.66667C2.19149 14.7916 1.73577 14.6028 1.39977 14.2668C1.06376 13.9308 0.875 13.4751 0.875 12.9999V2.99992C0.875 2.52474 1.06376 2.06902 1.39977 1.73302C1.73577 1.39702 2.19149 1.20825 2.66667 1.20825H6.39137C6.42446 1.20832 6.45617 1.2215 6.47956 1.24492L6.47978 1.24514L11.0881 5.85347ZM7.39522 2.48803L6.54167 1.63448V2.84159V5.29158V5.79158H7.04167H9.49167H10.6988L9.84522 4.93803L7.39522 2.48803ZM6.29167 1.95825V1.45825H5.79167H2.66667C2.25779 1.45825 1.86566 1.62068 1.57654 1.9098C1.28743 2.19891 1.125 2.59104 1.125 2.99992V12.9999C1.125 13.4088 1.28742 13.8009 1.57654 14.09C1.86566 14.3792 2.25779 14.5416 2.66667 14.5416H9.33333C9.74221 14.5416 10.1343 14.3792 10.4235 14.09C10.7126 13.8009 10.875 13.4088 10.875 12.9999V6.54158V6.04158H10.375H6.42114C6.38714 6.04061 6.35476 6.02668 6.33067 6.00259C6.30657 5.97849 6.29264 5.94612 6.29167 5.91211V1.95825Z"
                                fill="#374151"
                                stroke="#605DEC"
                              />
                            </svg>
                            <span className="ms-2">{file.name}</span>
                          </p>
                        ))}
                      </p>
                    )}
                  </div>
                )}

                <div className="col-12">
                  <div className={`${styles.uploadbtn} mt-3`}>
                    <Button
                      type="submit"
                      className="d-flex justify-content-center"
                    >
                      {loading ? (
                        <span className={`${styles.loader_spinner} p-1`}></span>
                      ) : (
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.2199 17.75H2.77994C2.43316 17.7359 2.09256 17.6535 1.77765 17.5075C1.46274 17.3616 1.17969 17.155 0.944701 16.8996C0.709711 16.6442 0.527393 16.3449 0.408179 16.019C0.288964 15.693 0.235194 15.3468 0.249944 15V12C0.249944 11.8011 0.328961 11.6103 0.469614 11.4697C0.610266 11.329 0.801031 11.25 0.999944 11.25C1.19886 11.25 1.38962 11.329 1.53027 11.4697C1.67093 11.6103 1.74994 11.8011 1.74994 12V15C1.72412 15.2969 1.81359 15.5924 1.99977 15.8251C2.18596 16.0579 2.45459 16.21 2.74994 16.25H15.2199C15.5153 16.21 15.7839 16.0579 15.9701 15.8251C16.1563 15.5924 16.2458 15.2969 16.2199 15V12C16.2199 11.8011 16.299 11.6103 16.4396 11.4697C16.5803 11.329 16.771 11.25 16.9699 11.25C17.1689 11.25 17.3596 11.329 17.5003 11.4697C17.6409 11.6103 17.7199 11.8011 17.7199 12V15C17.7499 15.6954 17.504 16.3744 17.0358 16.8894C16.5676 17.4045 15.915 17.7137 15.2199 17.75Z"
                              fill="#134E4A"
                            />
                            <path
                              d="M12.9998 5.75007C12.9013 5.75054 12.8037 5.73132 12.7127 5.69354C12.6216 5.65576 12.5391 5.60018 12.4698 5.53007L8.99985 2.06007L5.52985 5.53007C5.38767 5.66255 5.19963 5.73468 5.00532 5.73125C4.81102 5.72782 4.62564 5.64911 4.48822 5.51169C4.35081 5.37428 4.2721 5.1889 4.26867 4.9946C4.26524 4.80029 4.33737 4.61225 4.46985 4.47007L8.46985 0.470073C8.61047 0.329622 8.8011 0.250732 8.99985 0.250732C9.1986 0.250732 9.38922 0.329622 9.52985 0.470073L13.5298 4.47007C13.6703 4.6107 13.7492 4.80132 13.7492 5.00007C13.7492 5.19882 13.6703 5.38945 13.5298 5.53007C13.4606 5.60018 13.3781 5.65576 13.287 5.69354C13.196 5.73132 13.0984 5.75054 12.9998 5.75007Z"
                              fill="#374151"
                            />
                            <path
                              d="M9 12.75C8.80189 12.7474 8.61263 12.6676 8.47253 12.5275C8.33244 12.3874 8.25259 12.1981 8.25 12V1C8.25 0.801088 8.32902 0.610322 8.46967 0.46967C8.61032 0.329018 8.80109 0.25 9 0.25C9.19891 0.25 9.38968 0.329018 9.53033 0.46967C9.67098 0.610322 9.75 0.801088 9.75 1V12C9.74741 12.1981 9.66756 12.3874 9.52747 12.5275C9.38737 12.6676 9.19811 12.7474 9 12.75Z"
                              fill="#374151"
                            />
                          </svg>
                          <span className="ms-2">Upload</span>
                        </span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UploadQuiz;
