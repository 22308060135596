import { api } from "../api/api";

export const getQuizCount = async () => {
  try {
    const response = await api.get(`api/Quizzes/QuizCount`);
    return response?.data?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};
