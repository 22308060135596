import React, { useEffect, useRef, useState } from "react";
import styles from "./quizHistory.module.scss";
import Table from "../../../components/Table/Table";
import {
  quizHistoryColumns,
  quizHistoryDetailsColumn,
} from "../../../components/Table-Columns/TableColumns";
import * as constants from "../../../constants/tableConstants";
import {
  getQuizHistory,
  getUserQuizDetails,
} from "../../../services/orderHistory";
import { Dialog } from "primereact/dialog";
import { useLoginUserData } from "../../../store/login";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";

const QuizHistory = () => {
  const toast = useRef(null);
  const [quizHistory, setQuizHistory] = useState([]);
  const[quizHistoryCopy,setQuizHistoryCopy]=useState([])
  const [showDialog, setShowDialog] = useState(false);
  const [quizAttemptsList, setQuizAttemptsList] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [downloading, setDownloading] = useState({});
  const[showSkeleton,setShowSkeleton]=useState(false);
  const getQuizHistoryDetails = JSON.parse(localStorage.getItem("user"));
  const { loginUserData } = useLoginUserData();
  const superAdminLogin = loginUserData && loginUserData.roleId === 1;
  useEffect(() => {
    const fetchQuizHistory = async () => {
      try {
        setShowSkeleton(true);
        const userId = superAdminLogin
          ? selectedUser
          : getQuizHistoryDetails?.userId;
        const response = await getQuizHistory(userId);
        setQuizHistory(response);
        setQuizHistoryCopy(response)
      } catch (error) {
        showError(error?.message);
        setShowSkeleton(false);
      } finally{
        setShowSkeleton(false);
      }
    };
    if (!superAdminLogin || selectedUser) {
      fetchQuizHistory();
    }
  }, [superAdminLogin, selectedUser]);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getUserQuizDetails();
        if (response) {
          const userOptions = response?.map((user) => ({
            label: user?.userName,
            value: user?.userId,
          }));
          setUserDetails(userOptions);
        } 
      } catch (error) {
        showError(error?.message);
      }
    };
    if (superAdminLogin) {
      fetchUserDetails();
    }
  }, [superAdminLogin]);
  const statusUpdate = {
    1: 'Not Completed',
    2: 'In progress',
    3: 'Completed',
    4: 'Canceled'
  };
  const downloadCertificate = (userQuizId) => {
    try {
      setDownloading((prev) => ({ ...prev, [userQuizId]: true }));
      const url = `${process.env.REACT_APP_BASE_URL}/api/Certificate?userQuizId=${userQuizId}`;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", `Bearer ${loginUserData.token}`);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "certificate.pdf";
          link.click();
        } else if (xhr.status === 401) {
          showError("Quiz is NOT Taken/Completed");
          setDownloading((prev) => ({ ...prev, [userQuizId]: false }));
        } else {
          showError("Quiz is NOT Taken/Completed");
          setDownloading((prev) => ({ ...prev, [userQuizId]: false }));
        }
      };

      xhr.onerror = function () {
        showError("Quiz is NOT Taken/Completed");
      };
      xhr.onloadend = function () {
        setDownloading((prev) => ({ ...prev, [userQuizId]: false }));
      };
      xhr.send();
    } catch (error) {
      showError(error?.title)
    }
  };

  const showError = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };


  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const quizHistoryDetails = quizHistory.map((quiz) => {
    return {
      domain: quiz?.domainName,
      topicname: quiz?.topicName,
      quizname: quiz?.quizname,
      dateoflastattempt: formatDate(quiz?.dateOfLastAttempt),
      highestscore: quiz?.highestScore,
      totalattempt: quiz?.totalQuizAttempts,
      certificate: quiz?.userQuizAttempts,
    };
  });

  const quizHistorydetailDialog = quizAttemptsList.map((attempt) => {
    return {
      attemptnumber: attempt?.attemptNumber,
      attempteddate: attempt?.attemptDate,
      score: attempt?.score,
      status:statusUpdate[attempt?.userQuizStatus],
      ...(superAdminLogin ? {}:{downloadcertificate: (
        <Button
          className={styles.certificateBtn}
          onClick={() => {
            downloadCertificate(attempt?.userQuizId);
          }}
        >
          {downloading[attempt?.userQuizId] ? (
            <div className={`${styles.loader_spinner}`}></div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M18.2567 21.2083H3.74333C3.33874 21.1918 2.94138 21.0957 2.57399 20.9255C2.20659 20.7552 1.87637 20.5142 1.60221 20.2162C1.32806 19.9182 1.11535 19.5691 0.976269 19.1888C0.837186 18.8085 0.774454 18.4046 0.791662 18V14.5C0.791662 14.2679 0.883849 14.0454 1.04794 13.8813C1.21204 13.7172 1.4346 13.625 1.66666 13.625C1.89873 13.625 2.12129 13.7172 2.28538 13.8813C2.44948 14.0454 2.54166 14.2679 2.54166 14.5V18C2.51154 18.3464 2.61591 18.6911 2.83313 18.9627C3.05035 19.2342 3.36375 19.4117 3.70833 19.4583H18.2567C18.6012 19.4117 18.9146 19.2342 19.1319 18.9627C19.3491 18.6911 19.4535 18.3464 19.4233 18V14.5C19.4233 14.2679 19.5155 14.0454 19.6796 13.8813C19.8437 13.7172 20.0663 13.625 20.2983 13.625C20.5304 13.625 20.753 13.7172 20.917 13.8813C21.0811 14.0454 21.1733 14.2679 21.1733 14.5V18C21.2083 18.8113 20.9214 19.6035 20.3752 20.2043C19.829 20.8052 19.0676 21.166 18.2567 21.2083Z"
                fill="#134E4A"
                style={{
                  fill: "#134E4A",
                  fill: "color(display-p3 0.0745 0.3059 0.2902)",
                  fillOpacity: "1",
                }}
              />
              <path
                d="M11 15.375C10.885 15.3755 10.7711 15.3531 10.6649 15.309C10.5588 15.265 10.4625 15.2001 10.3817 15.1183L5.715 10.4517C5.56044 10.2858 5.4763 10.0664 5.4803 9.83973C5.4843 9.61304 5.57613 9.39676 5.73645 9.23645C5.89676 9.07613 6.11304 8.9843 6.33973 8.9803C6.56641 8.9763 6.7858 9.06044 6.95167 9.215L11 13.2633L15.0483 9.215C15.2142 9.06044 15.4336 8.9763 15.6603 8.9803C15.887 8.9843 16.1032 9.07613 16.2636 9.23645C16.4239 9.39676 16.5157 9.61304 16.5197 9.83973C16.5237 10.0664 16.4396 10.2858 16.285 10.4517L11.6183 15.1183C11.5376 15.2001 11.4412 15.265 11.3351 15.309C11.2289 15.3531 11.115 15.3755 11 15.375Z"
                fill="#374151"
                style={{
                  fill: "#374151",
                  fill: "color(display-p3 0.2157 0.2549 0.3176)",
                  fillOpacity: 1,
                }}
              />
              <path
                d="M11 15.375C10.7689 15.372 10.5481 15.2788 10.3846 15.1154C10.2212 14.952 10.128 14.7311 10.125 14.5V1.66669C10.125 1.43462 10.2172 1.21206 10.3813 1.04797C10.5454 0.883874 10.7679 0.791687 11 0.791687C11.2321 0.791687 11.4546 0.883874 11.6187 1.04797C11.7828 1.21206 11.875 1.43462 11.875 1.66669V14.5C11.872 14.7311 11.7788 14.952 11.6154 15.1154C11.4519 15.2788 11.2311 15.372 11 15.375Z"
                fill="#374151"
                style={{
                  fill: "#374151",
                  fill: "color(display-p3 0.2157 0.2549 0.3176)",
                  fillOpacity: "1",
                }}
              />
            </svg>
          )}
        </Button>
      ),})
    };
  });

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
        setQuizHistory(quizHistoryCopy);
    } else {
      const data = quizHistory.filter((item) =>
        Object.values(item).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
      setQuizHistory(data);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className={`${styles.quizHistoryContent}`}>
        <h4 className="font_text semibold mt-1 mb-3">Quiz History</h4>
        {superAdminLogin && (
          <div className="col-9 col-sm-7 col-md-4">
            <Dropdown
              id="userId"
              placeholder="Select User"
              className="w-100 mb-3"
              options={userDetails}
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.value)}
            />
          </div>
        )}
        <div
          className={`${styles.searchquizDiv} px-3 py-4 d-flex justify-content-md-end`}
        >
          <div className="d-flex justify-content-between align-items-center bg-white w-25">
              <input
                className="form-control px-3 py-2 border-0"
                type="text"
                placeholder="Search"
                id="search"
                name="searchbar"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            <i className="pi pi-search pe-3" onClick={handleSearch}></i>
          </div>
        </div>
        <div>
        {showSkeleton ? (
          <div>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
            <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          </div>
        ) : (
          <Table
            content={quizHistoryDetails}
            rows={constants.defaultRows}
            rowsPerPageOptions={constants.rowsPerPageOptions}
            paginator={true}
            columnsData={quizHistoryColumns(
              styles,
              setShowDialog,
              setQuizAttemptsList,
            )}
            scrollable={constants.scrollable}
            emptyMessage={constants.emptyMessage}
            tableStyle={constants.tableStyle}
            currentPageReportTemplate={constants.currentPageReportTemplate}
            paginatorTemplate={constants.paginatorTemplate}
          ></Table>
        )}
          
        </div>
        {showDialog && (
          <Dialog
            className={styles.quizHistoryDialog}
            header="Sub Topics"
            visible={showDialog}
            style={{ width: "65vw" }}
            onHide={() => {
              if (!showDialog) return;
              setShowDialog(false);
            }}
          >
            <Table
              content={quizHistorydetailDialog}
              rows={constants.defaultRows}
              rowsPerPageOptions={constants.rowsPerPageOptions}
              paginator={true}
              columnsData={quizHistoryDetailsColumn(superAdminLogin)}
              scrollable={constants.scrollable}
              emptyMessage={constants.emptyMessage}
              tableStyle={constants.tableStyle}
              currentPageReportTemplate={constants.currentPageReportTemplate}
              paginatorTemplate={constants.paginatorTemplate}
            ></Table>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default QuizHistory;
