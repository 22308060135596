import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import * as constants from "../../../constants/tableConstants";
import { orderDetailsDisplayColumns } from "../../../components/Table-Columns/TableColumns";
import { Dialog } from "primereact/dialog";
import styles from "./orderDetails.module.scss";
import { getOrderIdDetails } from "../../../services/orderHistory";
import { cartHeaderTemplate } from "../../../components/Table-Header/TableHeader";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { orderDetailsFooter } from "../../../components/Table-Footer/TableFooter";
import { Tooltip } from "primereact/tooltip";

const OrderDetailsDisplay = ({ orderId, onBack }) => {
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [subTopicList, setSubTopicList] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);

  function formatDate(dateString) {
    if (!dateString || dateString === null) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const formatOrderDate = (dateString) => {
    if (!dateString) return "";
    const correctedDateString = dateString.endsWith("Z") ? dateString : `${dateString}Z`;
    const utcDate = new Date(correctedDateString);
    if (isNaN(utcDate.getTime())) return "Invalid date";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    return new Intl.DateTimeFormat("en-US", options).format(utcDate);
  };
  useEffect(() => {
    const fetchOrderDetails = async () => {
      setShowSkeleton(true);
      if (orderId) {
        try {
          const response = await getOrderIdDetails(orderId);
          setOrderDetails(response || {});
          setShowSkeleton(false);
        } catch (error) {
          setShowSkeleton(true);
          console.error(error?.message);
        }
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const orderTableData = orderDetails?.map((order) => ({
    domain: order?.domain,
    topic: order?.topic,
    subtopics: order?.subtopics,
    subscription: order?.subscription,
    expirydate: formatDate(order?.expiryDate),
    price: order?.price.toFixed(2),
    children: order?.children || [],
    isBundle: order?.isBundle || false,
  }));
  const total = orderTableData.reduce(
    (acc, order) => acc + parseFloat(order.price),
    0
  );
  const sortedOrderTableData = orderTableData.sort((a, b) => {
    return b.isBundle - a.isBundle; 
  });
  
  const navigateBackToHome = () => {
    navigate("/");
  };
  let tooltipContent = "";
  if (orderDetails[0]?.status === "Pending") {
    tooltipContent = "Payment is pending. Please wait for further updates.";
  } else if (orderDetails[0]?.status === "Failed") {
    tooltipContent = "Payment could not be processed";
  }
  return (
    <div className={`${styles.orderdetails_content}`}>
      <Tooltip target="#pending" content={tooltipContent} position="top" />
      <h4 className="font_text semibold mt-1 mb-3">Order Details</h4>
      <a
        href="#"
        className={`${styles.backarrow} d-flex align-items-center text-decoration-none mb-4`}
        onClick={onBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
        >
          <path
            d="M6.16547 11.625C6.08335 11.6254 6.00198 11.6094 5.92614 11.5779C5.85029 11.5464 5.7815 11.5001 5.7238 11.4417L0.723799 6.4417C0.606757 6.32451 0.541016 6.16566 0.541016 6.00003C0.541016 5.83441 0.606757 5.67556 0.723799 5.55837L5.7238 0.558368C5.84228 0.447968 5.99898 0.387866 6.1609 0.390722C6.32282 0.393579 6.47731 0.459172 6.59182 0.573683C6.70633 0.688194 6.77192 0.842682 6.77478 1.0046C6.77764 1.16652 6.71753 1.32322 6.60713 1.4417L2.0488 6.00003L6.60713 10.5584C6.72417 10.6756 6.78992 10.8344 6.78992 11C6.78992 11.1657 6.72417 11.3245 6.60713 11.4417C6.54943 11.5001 6.48064 11.5464 6.40479 11.5779C6.32895 11.6094 6.24758 11.6254 6.16547 11.625V11.625Z"
            fill="#14B8A6"
          />
          <path
            d="M12.8327 6.625H1.16602C1.00026 6.625 0.841284 6.55915 0.724074 6.44194C0.606864 6.32473 0.541016 6.16576 0.541016 6C0.541016 5.83424 0.606864 5.67527 0.724074 5.55806C0.841284 5.44085 1.00026 5.375 1.16602 5.375H12.8327C12.9984 5.375 13.1574 5.44085 13.2746 5.55806C13.3918 5.67527 13.4577 5.83424 13.4577 6C13.4577 6.16576 13.3918 6.32473 13.2746 6.44194C13.1574 6.55915 12.9984 6.625 12.8327 6.625Z"
            fill="#14B8A6"
          />
        </svg>
        <span className="ms-2">Back to Order History</span>
      </a>
      <div>
        <div className="d-flex">
          <p className={`${styles.orderId} mb-0`}>Order # {orderId}</p>
          <div
            className={`d-flex align-items-center ${styles.orderstatus} ms-2 ${
              orderDetails[0]?.status === "Paid"
                ? styles.orderstatusPaid
                : orderDetails[0]?.status === "Pending"
                ? styles.orderstatusPending
                : orderDetails[0]?.status === "Failed"
                ? styles.orderstatusFailed
                : ""
            }`}
          >
            Status:{showSkeleton?<Skeleton width="3rem" height="1rem"/>:`${orderDetails[0]?.status}`} 
          </div>
          <div className="ms-2">
            {orderDetails[0]?.status === "Pending" ||
            orderDetails[0]?.status === "Failed" ? (
              <span id="pending" data-pr-position="top">
                {" "}
                <svg
                  xmlns="
            http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clipPath="url(#clip0_2633_127498)">
                    <path
                      d="M8 7.6665V10.9998"
                      stroke="#0EA5E9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 5.00643L8.00667 4.99902"
                      stroke="#0EA5E9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.682 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.682 4.31875 14.6668 8.00065 14.6668Z"
                      stroke="#0EA5E9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2633_127498">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={`${styles.orderdate} mb-3 d-flex`}>
          Placed on {showSkeleton?<Skeleton width="8rem" height="2rem" className="ms-3"/>:`${formatOrderDate(orderDetails[0]?.orderPlacedOn)}`} 
        </div>
      </div>
      {showSkeleton ? (
        <div>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
          <Skeleton width="100%" className="mb-2" height="3rem"></Skeleton>
        </div>
      ) : (
        <div>
          <Table
            content={sortedOrderTableData}
            rowGroupMode="subheader"
            groupRowsBy="isBundle"
            rows={constants.defaultRows}
            rowsPerPageOptions={constants.rowsPerPageOptions}
            paginator={false}
            columnsData={orderDetailsDisplayColumns(
              styles,
              setShowDialog,
              setSubTopicList
            )}
            scrollable={constants.scrollable}
            emptyMessage={constants.emptyMessage}
            tableStyle={constants.tableStyle}
            currentPageReportTemplate={constants.currentPageReportTemplate}
            paginatorTemplate={constants.paginatorTemplate}
            headerTemplate={(data) => cartHeaderTemplate(data)}
            footerTemplate={() =>
              orderDetailsFooter(total, styles, navigateBackToHome)
            }
          ></Table>
        </div>
      )}

      {showDialog && (
        <Dialog
          header="Sub Topics"
          visible={showDialog}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!showDialog) return;
            setShowDialog(false);
          }}
        >
          <div className="pt-3">
            {subTopicList?.length > 0 &&
              subTopicList.map((subtopic) => {
                return <p>{subtopic}</p>;
              })}
            {subTopicList?.length === 0 && <p>No À la carte Items available</p>}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default OrderDetailsDisplay;
