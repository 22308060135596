import { api } from "../api/api";

export const contactUs = async (formdata) => {
    try {
        const response = await api.post(`/api/ContactUs`,formdata,{
            headers: {
              "Content-Type": "application/json",
            },
          })
        return response?.data?.result || [];
    }
    catch (error) {
        throw new Error(error?.response?.data?.title);
    }
}