import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import Navbar from './constants/header/header-nav';
import Footer from './constants/footer/footer';
import Routing from './Routing';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <div className='main_container'>
      <Routing/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
