import { api } from "../api/api";

const loginApi = async(credentials)=>{
    try{
        const response = await api.post(`/api/User/Login`, credentials);
        return response?.data?.result || [];
    } catch(error){
        throw new Error(error?.response?.data?.title);
    }
}
export default loginApi