import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import styles from './adminTab.module.scss'
import UploadQuiz from "../uploadQuiz/uploadQuiz";
import ViewQuiz from "../viewQuiz/viewQuiz";

const AdminTab = () => {
  const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const savedIndex = localStorage.getItem('activeTabIndex');
        if (savedIndex !== null) {
            setActiveIndex(parseInt(savedIndex, 10)); 
        }
    }, []);
    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        localStorage.setItem('activeTabIndex', e.index);
    };
  return (
    <div className="container-fluid">
      <div className="px-lg-5 mx-4 py-3">
        <div className={`${styles.tabheading}`}>
        <h4 className='mt-2 mb-4'>MANAGE QUIZZES</h4>
        </div>
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
          <TabPanel header="Upload">
            <UploadQuiz flag="create"/>
          </TabPanel>
          <TabPanel header="Update">
          <UploadQuiz flag="edit"/>
          </TabPanel>
          <TabPanel header="View / Add Images">
            <ViewQuiz/>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default AdminTab;
