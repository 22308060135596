import './PageNotFound.css'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
const PageNotFound = () =>{
    const location = useLocation();
    const unauthorized = location.state?.unauthorized
    return (
        unauthorized?
       <div className="pagenotFound_container">
          <h1>Your are Unauthorized!!</h1>
       </div>
       :  <div className="pagenotFound_container">
       <h1>404 Page not found!!</h1>
    </div>
    )
}

export default PageNotFound;